import {
	apiRequest,
	updateActivities,
	updateNotification,
	updateSpinner,
} from "../actions";
import {
	closeCreateActivityDrawer,
	closeUpdateActivityDrawer,
} from "../actions/activity";
import {
	CREATE_ACTIVITY,
	CREATE_ACTIVITY_SUCCESS,
	DELETE_ACTIVITY,
	DELETE_ACTIVITY_SUCCESS,
	GET_ACTIVITIES,
	GET_ACTIVITIES_SUCCESS,
	GET_ACTIVITY,
	GET_ACTIVITY_SUCCESS,
	UPDATE_ACTIVITY,
	UPDATE_ACTIVITY_SUCCESS,
	UPDATE_NOTIFICATION_API,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Activities -----------------------------------------------------------------
export const getActivitiesFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_ACTIVITIES) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Activities . . . " })
			);
			dispatch(
				apiRequest(
					"GET",
					URL,
					null,
					GET_ACTIVITIES_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_ACTIVITY) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading Activity . . . " })
			);
			dispatch(
				apiRequest(
					"GET",
					newURL,
					null,
					GET_ACTIVITY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_ACTIVITY) {
			dispatch(
				updateSpinner({ show: true, message: "Creating New Activity . . . " })
			);
			dispatch(
				apiRequest(
					"POST",
					URL,
					action.payload,
					CREATE_ACTIVITY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_ACTIVITY) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({ show: true, message: "Updating Activity . . . " })
			);
			dispatch(
				apiRequest(
					"PATCH",
					newURL,
					action.payload,
					UPDATE_ACTIVITY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_ACTIVITY) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Activity . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_ACTIVITY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processActivitiesFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_ACTIVITIES_SUCCESS) {
			dispatch(updateActivities({ activities: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_ACTIVITY_SUCCESS) {
			dispatch(updateActivities({ selectedActivity: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_ACTIVITY_SUCCESS) {
			let activities = getState().activity.activities;
			activities.push(action.payload);
			dispatch(updateActivities({ activities }));
			dispatch(closeCreateActivityDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Activity Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_ACTIVITY_SUCCESS) {
			let activities = getState().activity.activities;
			let updatedActivities = activities.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateActivities({ activities: updatedActivities }));
			dispatch(closeUpdateActivityDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Activity Updated",
				})
			);
		}

		if (action.type === DELETE_ACTIVITY_SUCCESS) {
			let activities = getState().activity.activities;
			let updatedActivities = activities.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateActivities({ activities: updatedActivities }));
			dispatch(closeUpdateActivityDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Activity Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const activityMdl = [getActivitiesFlow, processActivitiesFlow];
