import { UPDATE_DMCS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_DMCS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createDmcDrawer: false,
	updateDmcDrawer: false,
	readDmcDrawer: false,
	dmcs: [],
	selectedDmc: null,
	authKey: null,
};
export default function dmcAcReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
