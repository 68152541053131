import {
	CREATE_COMPANY_INFO,
	DELETE_COMPANY_INFO,
	GET_COMPANY_INFO,
	GET_COMPANY_INFOS,
	UPDATE_COMPANY_INFO,
	UPDATE_COMPANY_INFOS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateCompanyInfoDrawer() {
	return {
		type: UPDATE_COMPANY_INFOS,
		payload: { createCompanyInfoDrawer: true },
	};
}

export function closeCreateCompanyInfoDrawer() {
	return {
		type: UPDATE_COMPANY_INFOS,
		payload: { createCompanyInfoDrawer: false },
	};
}

export function openUpdateCompanyInfoDrawer() {
	return {
		type: UPDATE_COMPANY_INFOS,
		payload: { updateCompanyInfoDrawer: true },
	};
}

export function closeUpdateCompanyInfoDrawer() {
	return {
		type: UPDATE_COMPANY_INFOS,
		payload: { updateCompanyInfoDrawer: false },
	};
}

export function openReadCompanyInfoDrawer() {
	return {
		type: UPDATE_COMPANY_INFOS,
		payload: { readCompanyInfoDrawer: true },
	};
}

export function closeReadCompanyInfoDrawer() {
	return {
		type: UPDATE_COMPANY_INFOS,
		payload: { readCompanyInfoDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllCompanyInfos = () => {
	return {
		type: GET_COMPANY_INFOS,
	};
};

export const getSelectedCompanyInfo = (payload) => {
	return {
		type: GET_COMPANY_INFO,
		payload,
	};
};

export const createNewCompanyInfo = (payload) => {
	return {
		type: CREATE_COMPANY_INFO,
		payload,
	};
};

export const updateCompanyInfo = (payload) => {
	return {
		type: UPDATE_COMPANY_INFO,
		payload,
	};
};

export const deleteCompanyInfo = (payload) => {
	return {
		type: DELETE_COMPANY_INFO,
		payload,
	};
};

export const updateCompanyInfos = (data) => {
	return { type: UPDATE_COMPANY_INFOS, payload: data };
};
