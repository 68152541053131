import {
	CREATE_EMPLOYEE,
	DELETE_EMPLOYEE,
	GET_EMPLOYEE,
	GET_LOGIN_EMPLOYEE,
	GET_EMPLOYEES,
	UPDATE_EMPLOYEE,
	UPDATE_EMPLOYEES,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateEmployeeDrawer() {
	return {
		type: UPDATE_EMPLOYEES,
		payload: { createEmployeeDrawer: true },
	};
}

export function closeCreateEmployeeDrawer() {
	return {
		type: UPDATE_EMPLOYEES,
		payload: { createEmployeeDrawer: false },
	};
}

export function openUpdateEmployeeDrawer() {
	return {
		type: UPDATE_EMPLOYEES,
		payload: { updateEmployeeDrawer: true },
	};
}

export function closeUpdateEmployeeDrawer() {
	return {
		type: UPDATE_EMPLOYEES,
		payload: { updateEmployeeDrawer: false },
	};
}

export function openReadEmployeeDrawer() {
	return {
		type: UPDATE_EMPLOYEES,
		payload: { readEmployeeDrawer: true },
	};
}

export function closeReadEmployeeDrawer() {
	return {
		type: UPDATE_EMPLOYEES,
		payload: { readEmployeeDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllEmployees = () => {
	return {
		type: GET_EMPLOYEES,
	};
};

export const getSelectedEmployee = (payload) => {
	return {
		type: GET_EMPLOYEE,
		payload,
	};
};
export const getLogggedInEmployee = (payload) => {
	return {
		type: GET_LOGIN_EMPLOYEE,
		payload,
	};
};

export const createNewEmployee = (payload) => {
	return {
		type: CREATE_EMPLOYEE,
		payload,
	};
};

export const updateEmployee = (payload) => {
	return {
		type: UPDATE_EMPLOYEE,
		payload,
	};
};

export const deleteEmployee = (payload) => {
	return {
		type: DELETE_EMPLOYEE,
		payload,
	};
};

export const updateEmployees = (data) => {
	return { type: UPDATE_EMPLOYEES, payload: data };
};
