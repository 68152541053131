import { UPDATE_DAYS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_DAYS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createDayDrawer: false,
	updateDayDrawer: false,
	readDayDrawer: false,
	days: [],
	selectedDay: null,
	authKey: null,
};
export default function dayReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
