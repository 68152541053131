import {
	CREATE_TRIP_ORDER,
	DELETE_TRIP_ORDER,
	GET_TRIP_ORDER,
	GET_TRIP_ORDERS,
	UPDATE_TRIP_ORDER,
	UPDATE_TRIP_ORDERS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateTripOrderDrawer() {
	return {
		type: UPDATE_TRIP_ORDERS,
		payload: { createTripOrderDrawer: true },
	};
}

export function closeCreateTripOrderDrawer() {
	return {
		type: UPDATE_TRIP_ORDERS,
		payload: { createTripOrderDrawer: false },
	};
}

export function openUpdateTripOrderDrawer() {
	return {
		type: UPDATE_TRIP_ORDERS,
		payload: { updateTripOrderDrawer: true },
	};
}

export function closeUpdateTripOrderDrawer() {
	return {
		type: UPDATE_TRIP_ORDERS,
		payload: { updateTripOrderDrawer: false },
	};
}

export function openReadTripOrderDrawer() {
	return {
		type: UPDATE_TRIP_ORDERS,
		payload: { readTripOrderDrawer: true },
	};
}

export function closeReadTripOrderDrawer() {
	return {
		type: UPDATE_TRIP_ORDERS,
		payload: { readTripOrderDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllTripOrders = () => {
	return {
		type: GET_TRIP_ORDERS,
	};
};

export const getSelectedTripOrder = (payload) => {
	return {
		type: GET_TRIP_ORDER,
		payload,
	};
};

export const createNewTripOrder = (payload) => {
	return {
		type: CREATE_TRIP_ORDER,
		payload,
	};
};

export const updateTripOrder = (payload) => {
	return {
		type: UPDATE_TRIP_ORDER,
		payload,
	};
};

export const deleteTripOrder = (payload) => {
	return {
		type: DELETE_TRIP_ORDER,
		payload,
	};
};

export const updateTripOrders = (data) => {
	return { type: UPDATE_TRIP_ORDERS, payload: data };
};
