import {
	CREATE_FAQ,
	DELETE_FAQ,
	GET_FAQ,
	GET_FAQS,
	UPDATE_FAQ,
	UPDATE_FAQS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateFaqDrawer() {
	return {
		type: UPDATE_FAQS,
		payload: { createFaqDrawer: true },
	};
}

export function closeCreateFaqDrawer() {
	return {
		type: UPDATE_FAQS,
		payload: { createFaqDrawer: false },
	};
}

export function openUpdateFaqDrawer() {
	return {
		type: UPDATE_FAQS,
		payload: { updateFaqDrawer: true },
	};
}

export function closeUpdateFaqDrawer() {
	return {
		type: UPDATE_FAQS,
		payload: { updateFaqDrawer: false },
	};
}

export function openReadFaqDrawer() {
	return {
		type: UPDATE_FAQS,
		payload: { readFaqDrawer: true },
	};
}

export function closeReadFaqDrawer() {
	return {
		type: UPDATE_FAQS,
		payload: { readFaqDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllFaqs = () => {
	return {
		type: GET_FAQS,
	};
};

export const getSelectedFaq = (payload) => {
	return {
		type: GET_FAQ,
		payload,
	};
};

export const createNewFaq = (payload) => {
	return {
		type: CREATE_FAQ,
		payload,
	};
};

export const updateFaq = (payload) => {
	return {
		type: UPDATE_FAQ,
		payload,
	};
};

export const deleteFaq = (payload) => {
	return {
		type: DELETE_FAQ,
		payload,
	};
};

export const updateFaqs = (data) => {
	return { type: UPDATE_FAQS, payload: data };
};
