import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateVouchers,
} from "../actions";
import {
	closeCreateVoucherDrawer,
	closeUpdateVoucherDrawer,
} from "../actions/voucher";
import {
	CREATE_VOUCHER,
	CREATE_VOUCHER_SUCCESS,
	DELETE_VOUCHER,
	DELETE_VOUCHER_SUCCESS,
	GET_VOUCHER,
	GET_VOUCHERS,
	GET_VOUCHERS_SUCCESS,
	GET_VOUCHER_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_VOUCHER,
	UPDATE_VOUCHER_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Vouchers -----------------------------------------------------------------
export const getVouchersFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_VOUCHERS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Vouchers . . . " })
			);
			dispatch(
				listRequest(
					"listVouchers",
					null,
					GET_VOUCHERS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_VOUCHER) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading Voucher . . . " })
			);
			dispatch(
				getSingleRequest(
					"getVoucher",
					action.payload,
					GET_VOUCHER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_VOUCHER) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Voucher . . . ",
				})
			);
			dispatch(
				updateRequest(
					"createVoucher",
					action.payload,
					CREATE_VOUCHER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_VOUCHER) {
			dispatch(
				updateSpinner({ show: true, message: "Updating Voucher . . . " })
			);
			dispatch(
				updateRequest(
					"updateVoucher",
					action.payload,
					UPDATE_VOUCHER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_VOUCHER) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Voucher . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_VOUCHER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processVouchersFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_VOUCHERS_SUCCESS) {
			dispatch(updateVouchers({ vouchers: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_VOUCHER_SUCCESS) {
			dispatch(updateVouchers({ selectedVoucher: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_VOUCHER_SUCCESS) {
			let vouchers = getState().voucher.vouchers;
			vouchers.push(action.payload);
			dispatch(updateVouchers({ vouchers }));
			dispatch(closeCreateVoucherDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Voucher Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_VOUCHER_SUCCESS) {
			let vouchers = getState().voucher.vouchers;
			let updatedVouchers = vouchers.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(
				updateVouchers({ Vouchers: updatedVouchers, selectedVoucher: null })
			);
			dispatch(closeUpdateVoucherDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Voucher Updated",
				})
			);
		}

		if (action.type === DELETE_VOUCHER_SUCCESS) {
			let Vouchers = getState().Voucher.Vouchers;
			let updatedVouchers = Vouchers.filter((el) => el.id !== action.deleteId);
			dispatch(updateVouchers({ Vouchers: updatedVouchers }));
			dispatch(closeUpdateVoucherDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Voucher Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const voucherMdl = [getVouchersFlow, processVouchersFlow];
