import {
	CREATE_ACTIVITY,
	DELETE_ACTIVITY,
	GET_ACTIVITIES,
	GET_ACTIVITY,
	UPDATE_ACTIVITIES,
	UPDATE_ACTIVITY,
} from "../constants";

// ------------------------------------
// Actions
// ------------------------------------
export function openCreateActivityDrawer() {
	return {
		type: UPDATE_ACTIVITIES,
		payload: { createActivityDrawer: true },
	};
}

export function closeCreateActivityDrawer() {
	return {
		type: UPDATE_ACTIVITIES,
		payload: { createActivityDrawer: false },
	};
}

export function openUpdateActivityDrawer() {
	return {
		type: UPDATE_ACTIVITIES,
		payload: { updateActivityDrawer: true },
	};
}

export function closeUpdateActivityDrawer() {
	return {
		type: UPDATE_ACTIVITIES,
		payload: { updateActivityDrawer: false },
	};
}

export function openReadActivityDrawer() {
	return {
		type: UPDATE_ACTIVITIES,
		payload: { readActivityDrawer: true },
	};
}

export function closeReadActivityDrawer() {
	return {
		type: UPDATE_ACTIVITIES,
		payload: { readActivityDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllActivities = () => {
	return {
		type: GET_ACTIVITIES,
	};
};

export const getSelectedActivity = (payload) => {
	return {
		type: GET_ACTIVITY,
		payload,
	};
};

export const createNewActivity = (payload) => {
	return {
		type: CREATE_ACTIVITY,
		payload,
	};
};

export const updateActivity = (payload) => {
	return {
		type: UPDATE_ACTIVITY,
		payload,
	};
};

export const deleteActivity = (payload) => {
	return {
		type: DELETE_ACTIVITY,
		payload,
	};
};

export const updateActivities = (data) => {
	return { type: UPDATE_ACTIVITIES, payload: data };
};
