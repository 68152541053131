import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateDays,
	updateNotification,
	updateSpinner,
} from "../actions";
import { closeCreateDayDrawer, closeUpdateDayDrawer } from "../actions/day";
import {
	CREATE_DAY,
	CREATE_DAY_SUCCESS,
	DELETE_DAY,
	DELETE_DAY_SUCCESS,
	GET_DAY,
	GET_DAYS,
	GET_DAYS_SUCCESS,
	GET_DAY_SUCCESS,
	UPDATE_DAY,
	UPDATE_DAY_SUCCESS,
	UPDATE_NOTIFICATION_API,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Days -----------------------------------------------------------------
export const getDaysFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DAYS) {
			dispatch(updateSpinner({ show: true, message: "Loading Days . . . " }));
			// dispatch(
			// 	apiRequest("GET", URL, null, GET_DAYS_SUCCESS, UPDATE_NOTIFICATION_API)
			// );
			dispatch(
				listRequest("listDays", null, GET_DAYS_SUCCESS, UPDATE_NOTIFICATION_API)
			);
		}
		if (action.type === GET_DAY) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(updateSpinner({ show: true, message: "Loading Day . . . " }));
			// dispatch(
			// 	apiRequest("GET", newURL, null, GET_DAY_SUCCESS, UPDATE_NOTIFICATION_API)
			// );
			dispatch(
				getSingleRequest(
					"getDay",
					action.payload,
					GET_DAY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_DAY) {
			dispatch(
				updateSpinner({ show: true, message: "Creating New Day . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"POST",
			// 		URL,
			// 		action.payload,
			// 		CREATE_DAY_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				updateRequest(
					"createDay",
					action.payload,
					CREATE_DAY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_DAY) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(updateSpinner({ show: true, message: "Updating Day . . . " }));
			// dispatch(
			// 	apiRequest(
			// 		"PATCH",
			// 		newURL,
			// 		action.payload,
			// 		UPDATE_DAY_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				updateRequest(
					"updateDay",
					action.payload,
					UPDATE_DAY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_DAY) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(updateSpinner({ show: true, message: "Deleting Day . . . " }));
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_DAY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processDaysFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DAYS_SUCCESS) {
			dispatch(updateDays({ days: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_DAY_SUCCESS) {
			dispatch(updateDays({ selectedDay: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_DAY_SUCCESS) {
			let days = getState().day.days;
			days.push(action.payload);
			dispatch(updateDays({ days }));
			dispatch(closeCreateDayDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Day Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_DAY_SUCCESS) {
			let days = getState().day.days;
			let updatedDays = days.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateDays({ days: updatedDays, selectedDay: null }));
			dispatch(closeUpdateDayDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Day Updated",
				})
			);
		}

		if (action.type === DELETE_DAY_SUCCESS) {
			let days = getState().day.days;
			let updatedDays = days.filter((el) => el.id !== action.deleteId);
			dispatch(updateDays({ days: updatedDays }));
			dispatch(closeUpdateDayDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Day Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const dayMdl = [getDaysFlow, processDaysFlow];
