import {
	GET_LIST_QUERY,
	GET_SINGLE_QUERY,
	UPDATE_MUTATION,
} from "../constants";
export const listRequest = (method, body, onSuccess, onError) => {
	return {
		type: GET_LIST_QUERY,
		payload: body,
		meta: { method, onSuccess, onError },
	};
};

export const getSingleRequest = (method, body, onSuccess, onError) => {
	return {
		type: GET_SINGLE_QUERY,
		payload: body,
		meta: { method, onSuccess, onError },
	};
};

export const updateRequest = (method, body, onSuccess, onError) => {
	return {
		type: UPDATE_MUTATION,
		payload: body,
		meta: { method, onSuccess, onError },
	};
};
