import {
  apiRequest,
  listRequest,
  updateRequest,
  getSingleRequest,
  updateNotification,
  updateSpinner,
  updateRoles,
} from "../actions";
import { closeCreateRoleDrawer, closeUpdateRoleDrawer } from "../actions/role";
import {
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  GET_ROLE,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLE_SUCCESS,
  UPDATE_NOTIFICATION_API,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Roles -----------------------------------------------------------------
export const getRolesFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_ROLES) {
      dispatch(updateSpinner({ show: true, message: "Loading Roles . . . " }));
      dispatch(
        listRequest(
          "listRoles",
          null,
          GET_ROLES_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === GET_ROLE) {
      let newURL = `${URL}/${action.payload}`;
      dispatch(updateSpinner({ show: true, message: "Loading Role . . . " }));
      dispatch(
        getSingleRequest(
          "getRole",
          action.payload,
          GET_ROLE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === CREATE_ROLE) {
      dispatch(
        updateSpinner({
          show: true,
          message: "Creating New Role . . . ",
        })
      );
      dispatch(
        updateRequest(
          "createRole",
          action.payload,
          CREATE_ROLE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === UPDATE_ROLE) {
      dispatch(updateSpinner({ show: true, message: "Updating Role . . . " }));
      dispatch(
        updateRequest(
          "updateRole",
          action.payload,
          UPDATE_ROLE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === DELETE_ROLE) {
      let newURL = `${URL}/${action.payload.id}`;
      console.log("Id to delete", action.payload.id);
      dispatch(updateSpinner({ show: true, message: "Deleting Role . . . " }));
      dispatch(
        apiRequest(
          "DELETE",
          newURL,
          action.payload,
          DELETE_ROLE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
  };
export const processRolesFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_ROLES_SUCCESS) {
      dispatch(updateRoles({ roles: action.payload }));
      dispatch(updateSpinner({ show: false, message: "" }));
    }

    if (action.type === GET_ROLE_SUCCESS) {
      dispatch(updateRoles({ selectedRole: action.payload }));
      dispatch(updateSpinner({ show: false, message: "" }));
    }
    if (action.type === CREATE_ROLE_SUCCESS) {
      let roles = getState().role.roles;
      roles.push(action.payload);
      dispatch(updateRoles({ roles }));
      dispatch(closeCreateRoleDrawer());
      dispatch(updateSpinner({ show: false, message: "" }));
      dispatch(
        updateNotification({
          type: "success",
          message: "Success",
          description: "Role Created Cuccessfully",
        })
      );
    }

    if (action.type === UPDATE_ROLE_SUCCESS) {
      let Roles = getState().role.roles;
      let updatedRoles = Roles.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });
      dispatch(updateRoles({ roles: updatedRoles, selectedRole: null }));
      dispatch(closeUpdateRoleDrawer());
      dispatch(updateSpinner({ show: false, message: "" }));
      dispatch(
        updateNotification({
          type: "info",
          message: "Information",
          description: "Role Updated",
        })
      );
      window.location.reload(true);
    }

    if (action.type === DELETE_ROLE_SUCCESS) {
      let Roles = getState().Role.Roles;
      let updatedRoles = Roles.filter((el) => el.id !== action.deleteId);
      dispatch(updateRoles({ roles: updatedRoles }));
      dispatch(closeUpdateRoleDrawer());
      dispatch(updateSpinner({ show: false, message: "" }));
      dispatch(
        updateNotification({
          type: "info",
          message: "Information",
          description: "Role Deleted",
        })
      );
    }
  };
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const roleMdl = [getRolesFlow, processRolesFlow];
