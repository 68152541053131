import { UPDATE_LEAD_PACKAGES } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_LEAD_PACKAGES]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createLeadPackageDrawer: false,
	updateLeadPackageDrawer: false,
	readLeadPackageDrawer: false,
	leadPackages: [],
	selectedLeadPackage: null,
	authKey: null,
};
export default function leadPackageReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
