import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updatePayments,
} from "../actions";
import {
	closeCreatePaymentDrawer,
	closeUpdatePaymentDrawer,
} from "../actions/payment";
import {
	CREATE_PAYMENT,
	CREATE_PAYMENT_SUCCESS,
	DELETE_PAYMENT,
	DELETE_PAYMENT_SUCCESS,
	GET_PAYMENT,
	GET_PAYMENTS,
	GET_PAYMENTS_SUCCESS,
	GET_PAYMENT_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_PAYMENT,
	UPDATE_PAYMENT_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Payments -----------------------------------------------------------------
export const getPaymentsFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_PAYMENTS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Payments . . . " })
			);

			dispatch(
				listRequest(
					"listPayments",
					null,
					GET_PAYMENTS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_PAYMENT) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading Payment . . . " })
			);

			dispatch(
				getSingleRequest(
					"getPayment",
					action.payload,
					GET_PAYMENT_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_PAYMENT) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Payment . . . ",
				})
			);

			dispatch(
				updateRequest(
					"createPayment",
					action.payload,
					CREATE_PAYMENT_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_PAYMENT) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({ show: true, message: "Updating Payment . . . " })
			);
			dispatch(
				apiRequest(
					"PATCH",
					newURL,
					action.payload,
					UPDATE_PAYMENT_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_PAYMENT) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Payment . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_PAYMENT_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processPaymentsFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_PAYMENTS_SUCCESS) {
			dispatch(updatePayments({ payments: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_PAYMENT_SUCCESS) {
			dispatch(updatePayments({ selectedPayment: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_PAYMENT_SUCCESS) {
			let payments = getState().payment.payments;
			payments.push(action.payload);
			dispatch(updatePayments({ payments }));
			dispatch(closeCreatePaymentDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Payment Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_PAYMENT_SUCCESS) {
			let Payments = getState().Payment.Payments;
			let updatedPayments = Payments.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updatePayments({ payments: updatedPayments }));
			dispatch(closeUpdatePaymentDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Payment Updated",
				})
			);
		}

		if (action.type === DELETE_PAYMENT_SUCCESS) {
			let Payments = getState().Payment.Payments;
			let updatedPayments = Payments.filter((el) => el.id !== action.deleteId);
			dispatch(updatePayments({ payments: updatedPayments }));
			dispatch(closeUpdatePaymentDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Payment Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const paymentMdl = [getPaymentsFlow, processPaymentsFlow];
