import {
	apiRequest,
	updateNotification,
	updateSpinner,
	updateFlightOrders,
	listRequest,
	updateRequest,
	getSingleRequest,
} from "../actions";
import {
	closeCreateFlightOrderDrawer,
	closeUpdateFlightOrderDrawer,
} from "../actions/flightOrder";
import {
	CREATE_FLIGHT_ORDER,
	CREATE_FLIGHT_ORDER_SUCCESS,
	DELETE_FLIGHT_ORDER,
	DELETE_FLIGHT_ORDER_SUCCESS,
	GET_FLIGHT_ORDER,
	GET_FLIGHT_ORDERS,
	GET_FLIGHT_ORDERS_SUCCESS,
	GET_FLIGHT_ORDER_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_FLIGHT_ORDER,
	UPDATE_FLIGHT_ORDER_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All FlightOrders -----------------------------------------------------------------
export const getFlightOrdersFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_FLIGHT_ORDERS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading FlightOrders . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		URL,
			// 		null,
			// 		GET_FLIGHT_ORDERS_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			
			dispatch(
				listRequest(
					"listViaOrders",
					null,
					GET_FLIGHT_ORDERS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_FLIGHT_ORDER) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading FlightOrder . . . " })
			);
			dispatch(
				apiRequest(
					"GET",
					newURL,
					null,
					GET_FLIGHT_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_FLIGHT_ORDER) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New FlightOrder . . . ",
				})
			);
			dispatch(
				apiRequest(
					"POST",
					URL,
					action.payload,
					CREATE_FLIGHT_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_FLIGHT_ORDER) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({ show: true, message: "Updating FlightOrder . . . " })
			);
			dispatch(
				apiRequest(
					"PATCH",
					newURL,
					action.payload,
					UPDATE_FLIGHT_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_FLIGHT_ORDER) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting FlightOrder . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_FLIGHT_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processFlightOrdersFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_FLIGHT_ORDERS_SUCCESS) {
			dispatch(updateFlightOrders({ flightOrders: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_FLIGHT_ORDER_SUCCESS) {
			dispatch(updateFlightOrders({ selectedFlightOrder: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_FLIGHT_ORDER_SUCCESS) {
			let FlightOrders = getState().FlightOrder.FlightOrders;
			FlightOrders.push(action.payload);
			dispatch(updateFlightOrders({ FlightOrders }));
			dispatch(closeCreateFlightOrderDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "FlightOrder Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_FLIGHT_ORDER_SUCCESS) {
			let FlightOrders = getState().FlightOrder.FlightOrders;
			let updatedFlightOrders = FlightOrders.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateFlightOrders({ flightOrders: updatedFlightOrders }));
			dispatch(closeUpdateFlightOrderDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "FlightOrder Updated",
				})
			);
		}

		if (action.type === DELETE_FLIGHT_ORDER_SUCCESS) {
			let FlightOrders = getState().FlightOrder.FlightOrders;
			let updatedFlightOrders = FlightOrders.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateFlightOrders({ flightOrders: updatedFlightOrders }));
			dispatch(closeUpdateFlightOrderDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "FlightOrder Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const flightOrderMdl = [getFlightOrdersFlow, processFlightOrdersFlow];
