import {
	apiRequest,
	updateNotification,
	updateSpinner,
	updateTransportations,
} from "../actions";
import {
	closeCreateTransportationDrawer,
	closeUpdateTransportationDrawer,
} from "../actions/transportation";
import {
	CREATE_TRANSPORTATION,
	CREATE_TRANSPORTATION_SUCCESS,
	DELETE_TRANSPORTATION,
	DELETE_TRANSPORTATION_SUCCESS,
	GET_TRANSPORTATION,
	GET_TRANSPORTATIONS,
	GET_TRANSPORTATIONS_SUCCESS,
	GET_TRANSPORTATION_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_TRANSPORTATION,
	UPDATE_TRANSPORTATION_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Transportations -----------------------------------------------------------------
export const getTransportationsFlow = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === GET_TRANSPORTATIONS) {
		dispatch(
			updateSpinner({ show: true, message: "Loading Transportations . . . " })
		);
		dispatch(
			apiRequest(
				"GET",
				URL,
				null,
				GET_TRANSPORTATIONS_SUCCESS,
				UPDATE_NOTIFICATION_API
			)
		);
	}
	if (action.type === GET_TRANSPORTATION) {
		let newURL = `${URL}/${action.payload}`;
		dispatch(
			updateSpinner({ show: true, message: "Loading Transportation . . . " })
		);
		dispatch(
			apiRequest(
				"GET",
				newURL,
				null,
				GET_TRANSPORTATION_SUCCESS,
				UPDATE_NOTIFICATION_API
			)
		);
	}
	if (action.type === CREATE_TRANSPORTATION) {
		dispatch(
			updateSpinner({
				show: true,
				message: "Creating New Transportation . . . ",
			})
		);
		dispatch(
			apiRequest(
				"POST",
				URL,
				action.payload,
				CREATE_TRANSPORTATION_SUCCESS,
				UPDATE_NOTIFICATION_API
			)
		);
	}
	if (action.type === UPDATE_TRANSPORTATION) {
		let newURL = `${URL}/${action.payload.id}`;
		dispatch(
			updateSpinner({ show: true, message: "Updating Transportation . . . " })
		);
		dispatch(
			apiRequest(
				"PATCH",
				newURL,
				action.payload,
				UPDATE_TRANSPORTATION_SUCCESS,
				UPDATE_NOTIFICATION_API
			)
		);
	}
	if (action.type === DELETE_TRANSPORTATION) {
		let newURL = `${URL}/${action.payload.id}`;
		console.log("Id to delete", action.payload.id);
		dispatch(
			updateSpinner({ show: true, message: "Deleting Transportation . . . " })
		);
		dispatch(
			apiRequest(
				"DELETE",
				newURL,
				action.payload,
				DELETE_TRANSPORTATION_SUCCESS,
				UPDATE_NOTIFICATION_API
			)
		);
	}
};
export const processTransportationsFlow = ({ dispatch, getState }) => (
	next
) => (action) => {
	next(action);
	if (action.type === GET_TRANSPORTATIONS_SUCCESS) {
		dispatch(updateTransportations({ transportations: action.payload }));
		dispatch(updateSpinner({ show: false, message: "" }));
	}

	if (action.type === GET_TRANSPORTATION_SUCCESS) {
		dispatch(updateTransportations({ selectedTransportation: action.payload }));
		dispatch(updateSpinner({ show: false, message: "" }));
	}
	if (action.type === CREATE_TRANSPORTATION_SUCCESS) {
		let transportations = getState().transportation.transportations;
		transportations.push(action.payload);
		dispatch(updateTransportations({ transportations }));
		dispatch(closeCreateTransportationDrawer());
		dispatch(updateSpinner({ show: false, message: "" }));
		dispatch(
			updateNotification({
				type: "success",
				message: "Success",
				description: "Transportation Created Cuccessfully",
			})
		);
	}

	if (action.type === UPDATE_TRANSPORTATION_SUCCESS) {
		let transportations = getState().transportation.transportations;
		let updatedTransportations = transportations.map((el) => {
			if (el.id === action.payload.id) {
				return action.payload;
			}
			return el;
		});
		dispatch(
			updateTransportations({ transportations: updatedTransportations })
		);
		dispatch(closeUpdateTransportationDrawer());
		dispatch(updateSpinner({ show: false, message: "" }));
		dispatch(
			updateNotification({
				type: "info",
				message: "Information",
				description: "Transportation Updated",
			})
		);
	}

	if (action.type === DELETE_TRANSPORTATION_SUCCESS) {
		let transportations = getState().transportation.transportations;
		let updatedTransportations = transportations.filter(
			(el) => el.id !== action.deleteId
		);
		dispatch(
			updateTransportations({ transportations: updatedTransportations })
		);
		dispatch(closeUpdateTransportationDrawer());
		dispatch(updateSpinner({ show: false, message: "" }));
		dispatch(
			updateNotification({
				type: "info",
				message: "Information",
				description: "Transportation Deleted",
			})
		);
	}
};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const transportationMdl = [
	getTransportationsFlow,
	processTransportationsFlow,
];
