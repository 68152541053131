import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updatePolicys,
} from "../actions";
import {
	closeCreatePolicyDrawer,
	closeUpdatePolicyDrawer,
} from "../actions/policy";
import {
	CREATE_POLICY,
	CREATE_POLICY_SUCCESS,
	DELETE_POLICY,
	DELETE_POLICY_SUCCESS,
	GET_POLICY,
	GET_POLICYS,
	GET_POLICYS_SUCCESS,
	GET_POLICY_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_POLICY,
	UPDATE_POLICY_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Policys -----------------------------------------------------------------
export const getPolicysFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_POLICYS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Policies . . . " })
			);
			dispatch(
				listRequest(
					"listPolicies",
					null,
					GET_POLICYS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_POLICY) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(updateSpinner({ show: true, message: "Loading Policy . . . " }));
			dispatch(
				getSingleRequest(
					"getPolicy",
					action.payload,
					GET_POLICY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_POLICY) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Policy . . . ",
				})
			);
			dispatch(
				updateRequest(
					"createPolicy",
					action.payload,
					CREATE_POLICY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_POLICY) {
			dispatch(
				updateSpinner({ show: true, message: "Updating Policy . . . " })
			);
			dispatch(
				updateRequest(
					"updatePolicy",
					action.payload,
					UPDATE_POLICY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_POLICY) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Policy . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_POLICY_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processPolicysFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_POLICYS_SUCCESS) {
			dispatch(updatePolicys({ policys: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_POLICY_SUCCESS) {
			dispatch(updatePolicys({ selectedPolicy: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_POLICY_SUCCESS) {
			let policys = getState().policy.policys;
			policys.push(action.payload);
			dispatch(updatePolicys({ policys }));
			dispatch(closeCreatePolicyDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Policy Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_POLICY_SUCCESS) {
			let policys = getState().policy.policys;
			let updatedPolicys = policys.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(
				updatePolicys({ policys: updatedPolicys, selectedPolicy: null })
			);
			dispatch(closeUpdatePolicyDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Policy Updated",
				})
			);
		}

		if (action.type === DELETE_POLICY_SUCCESS) {
			let policys = getState().policy.policys;
			let updatedPolicys = policys.filter((el) => el.id !== action.deleteId);
			dispatch(updatePolicys({ policys: updatedPolicys }));
			dispatch(closeUpdatePolicyDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Policy Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const policyMdl = [getPolicysFlow, processPolicysFlow];
