import {
	CREATE_DAY,
	DELETE_DAY,
	GET_DAY,
	GET_DAYS,
	UPDATE_DAY,
	UPDATE_DAYS,
} from "../constants";

// ------------------------------------
// Actions
// ------------------------------------
export function openCreateDayDrawer() {
	return {
		type: UPDATE_DAYS,
		payload: { createDayDrawer: true },
	};
}

export function closeCreateDayDrawer() {
	return {
		type: UPDATE_DAYS,
		payload: { createDayDrawer: false },
	};
}

export function openUpdateDayDrawer() {
	return {
		type: UPDATE_DAYS,
		payload: { updateDayDrawer: true },
	};
}

export function closeUpdateDayDrawer() {
	return {
		type: UPDATE_DAYS,
		payload: { updateDayDrawer: false },
	};
}

export function openReadDayDrawer() {
	return {
		type: UPDATE_DAYS,
		payload: { readDayDrawer: true },
	};
}

export function closeReadDayDrawer() {
	return {
		type: UPDATE_DAYS,
		payload: { readDayDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllDays = () => {
	return {
		type: GET_DAYS,
	};
};

export const getSelectedDay = (payload) => {
	return {
		type: GET_DAY,
		payload,
	};
};

export const createNewDay = (payload) => {
	return {
		type: CREATE_DAY,
		payload,
	};
};

export const updateDay = (payload) => {
	return {
		type: UPDATE_DAY,
		payload,
	};
};

export const deleteDay = (payload) => {
	return {
		type: DELETE_DAY,
		payload,
	};
};

export const updateDays = (data) => {
	return { type: UPDATE_DAYS, payload: data };
};
