import {
  apiRequest,
  listRequest,
  updateRequest,
  getSingleRequest,
  updateNotification,
  updateSpinner,
  updateEmployees,
} from "../actions";
import {
  closeCreateEmployeeDrawer,
  closeUpdateEmployeeDrawer,
} from "../actions/employee";
import {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE,
  GET_LOGIN_EMPLOYEE,
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_SUCCESS,
  GET_LOGIN_EMPLOYEE_SUCCESS,
  UPDATE_NOTIFICATION_API,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Employees -----------------------------------------------------------------
export const getEmployeesFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_EMPLOYEES) {
      dispatch(
        updateSpinner({ show: true, message: "Loading Employees . . . " })
      );

      dispatch(
        listRequest(
          "listEmployees",
          null,
          GET_EMPLOYEES_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === GET_EMPLOYEE) {
      dispatch(
        updateSpinner({ show: true, message: "Loading Employee . . . " })
      );

      dispatch(
        getSingleRequest(
          "getEmployee",
          action.payload,
          GET_EMPLOYEE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === GET_LOGIN_EMPLOYEE) {
      dispatch(
        updateSpinner({ show: true, message: "Loading Employee . . . " })
      );

      dispatch(
        getSingleRequest(
          "getEmployee",
          action.payload,
          GET_LOGIN_EMPLOYEE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === CREATE_EMPLOYEE) {
      dispatch(
        updateSpinner({
          show: true,
          message: "Creating New Employee . . . ",
        })
      );

      dispatch(
        updateRequest(
          "createEmployee",
          action.payload,
          CREATE_EMPLOYEE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === UPDATE_EMPLOYEE) {
      let newURL = `${URL}/${action.payload.id}`;
      dispatch(
        updateSpinner({ show: true, message: "Updating Employee . . . " })
      );

      dispatch(
        updateRequest(
          "updateEmployee",
          action.payload,
          UPDATE_EMPLOYEE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
    if (action.type === DELETE_EMPLOYEE) {
      let newURL = `${URL}/${action.payload.id}`;
      console.log("Id to delete", action.payload.id);
      dispatch(
        updateSpinner({ show: true, message: "Deleting Employee . . . " })
      );
      dispatch(
        apiRequest(
          "DELETE",
          newURL,
          action.payload,
          DELETE_EMPLOYEE_SUCCESS,
          UPDATE_NOTIFICATION_API
        )
      );
    }
  };
export const processEmployeesFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_EMPLOYEES_SUCCESS) {
      dispatch(updateEmployees({ employees: action.payload }));
      dispatch(updateSpinner({ show: false, message: "" }));
    }

    if (action.type === GET_EMPLOYEE_SUCCESS) {
      dispatch(updateEmployees({ selectedEmployee: action.payload }));
      dispatch(updateSpinner({ show: false, message: "" }));
    }
    if (action.type === GET_LOGIN_EMPLOYEE_SUCCESS) {
      dispatch(updateEmployees({ loggedInEmployee: action.payload }));
      dispatch(updateSpinner({ show: false, message: "" }));
    }
    if (action.type === CREATE_EMPLOYEE_SUCCESS) {
      let employees = getState().employee.employees;
      employees.push(action.payload);
      dispatch(updateEmployees({ employees }));
      dispatch(closeCreateEmployeeDrawer());
      dispatch(updateSpinner({ show: false, message: "" }));
      dispatch(
        updateNotification({
          type: "success",
          message: "Success",
          description: "Employee Created Successfully",
        })
      );
    }

    if (action.type === UPDATE_EMPLOYEE_SUCCESS) {
      let employees = getState().employee.employees;
      let updatedEmployees = employees.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });
      dispatch(
        updateEmployees({ employees: updatedEmployees, selectedEmployee: null })
      );
      dispatch(closeUpdateEmployeeDrawer());
      dispatch(updateSpinner({ show: false, message: "" }));
      dispatch(
        updateNotification({
          type: "info",
          message: "Information",
          description: "Employee Details Updated",
        })
      );
      window.location.reload(true);
    }

    if (action.type === DELETE_EMPLOYEE_SUCCESS) {
      let employees = getState().employee.employees;
      let updatedEmployees = employees.filter(
        (el) => el.id !== action.deleteId
      );
      dispatch(updateEmployees({ employees: updatedEmployees }));
      dispatch(closeUpdateEmployeeDrawer());
      dispatch(updateSpinner({ show: false, message: "" }));
      dispatch(
        updateNotification({
          type: "info",
          message: "Information",
          description: "Employee Deleted",
        })
      );
    }
  };
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const employeeMdl = [getEmployeesFlow, processEmployeesFlow];
