import { UPDATE_COMPANY_INFOS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_COMPANY_INFOS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createCompanyInfoDrawer: false,
	updateCompanyInfoDrawer: false,
	readCompanyInfoDrawer: false,
	companyInfos: [],
	selectedCompanyInfo: null,
	authKey: null,
};
export default function companyInfoReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
