import {
	CREATE_TRANSPORTATION,
	DELETE_TRANSPORTATION,
	GET_TRANSPORTATION,
	GET_TRANSPORTATIONS,
	UPDATE_TRANSPORTATION,
	UPDATE_TRANSPORTATIONS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateTransportationDrawer() {
	return {
		type: UPDATE_TRANSPORTATIONS,
		payload: { createTransportationDrawer: true },
	};
}

export function closeCreateTransportationDrawer() {
	return {
		type: UPDATE_TRANSPORTATIONS,
		payload: { createTransportationDrawer: false },
	};
}

export function openUpdateTransportationDrawer() {
	return {
		type: UPDATE_TRANSPORTATIONS,
		payload: { updateTransportationDrawer: true },
	};
}

export function closeUpdateTransportationDrawer() {
	return {
		type: UPDATE_TRANSPORTATIONS,
		payload: { updateTransportationDrawer: false },
	};
}

export function openReadTransportationDrawer() {
	return {
		type: UPDATE_TRANSPORTATIONS,
		payload: { readTransportationDrawer: true },
	};
}

export function closeReadTransportationDrawer() {
	return {
		type: UPDATE_TRANSPORTATIONS,
		payload: { readTransportationDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllTransportations = () => {
	return {
		type: GET_TRANSPORTATIONS,
	};
};

export const getSelectedTransportation = (payload) => {
	return {
		type: GET_TRANSPORTATION,
		payload,
	};
};

export const createNewTransportation = (payload) => {
	return {
		type: CREATE_TRANSPORTATION,
		payload,
	};
};

export const updateTransportation = (payload) => {
	return {
		type: UPDATE_TRANSPORTATION,
		payload,
	};
};

export const deleteTransportation = (payload) => {
	return {
		type: DELETE_TRANSPORTATION,
		payload,
	};
};

export const updateTransportations = (data) => {
	return { type: UPDATE_TRANSPORTATIONS, payload: data };
};
