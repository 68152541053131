import { UPDATE_FLIGHT_ORDERS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_FLIGHT_ORDERS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createFlightOrderDrawer: false,
	updateFlightOrderDrawer: false,
	readFlightOrderDrawer: false,
	flightOrders: [],
	selectedFlightOrder: null,
	authKey: null,
};
export default function flightOrderReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
