import { UPDATE_DEMO_PACKAGES } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_DEMO_PACKAGES]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createDemoPackageDrawer: false,
	updateDemoPackageDrawer: false,
	readDemoPackageDrawer: false,
	demoPackages: [],
	selectedDemoPackage: null,
	authKey: null,
};
export default function demoPackageReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
