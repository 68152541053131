import {
	apiRequest,
	updateNotification,
	updateSpinner,
	updateTripOrders,
	listRequest,
	updateRequest,
	getSingleRequest,
} from "../actions";
import {
	closeCreateTripOrderDrawer,
	closeUpdateTripOrderDrawer,
} from "../actions/tripOrder";
import {
	CREATE_TRIP_ORDER,
	CREATE_TRIP_ORDER_SUCCESS,
	DELETE_TRIP_ORDER,
	DELETE_TRIP_ORDER_SUCCESS,
	GET_TRIP_ORDER,
	GET_TRIP_ORDERS,
	GET_TRIP_ORDERS_SUCCESS,
	GET_TRIP_ORDER_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_TRIP_ORDER,
	UPDATE_TRIP_ORDER_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All TripOrders -----------------------------------------------------------------
export const getTripOrdersFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_TRIP_ORDERS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading TripOrders . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		URL,
			// 		null,
			// 		GET_TRIP_ORDERS_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				listRequest(
					"listTripOrders",
					null,
					GET_TRIP_ORDERS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_TRIP_ORDER) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading TripOrder . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		newURL,
			// 		null,
			// 		GET_TRIP_ORDER_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				getSingleRequest(
					"getTripOrder",
					action.payload,
					GET_TRIP_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_TRIP_ORDER) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New TripOrder . . . ",
				})
			);

			dispatch(
				updateRequest(
					"createTripOrder",
					action.payload,
					CREATE_TRIP_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_TRIP_ORDER) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({ show: true, message: "Updating TripOrder . . . " })
			);

			dispatch(
				updateRequest(
					"updateTripOrder",
					action.payload,
					UPDATE_TRIP_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_TRIP_ORDER) {
			let newURL = `${URL}/${action.payload.id}`;

			dispatch(
				updateSpinner({ show: true, message: "Deleting TripOrder . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_TRIP_ORDER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processTripOrdersFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_TRIP_ORDERS_SUCCESS) {
			dispatch(updateTripOrders({ tripOrders: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_TRIP_ORDER_SUCCESS) {
			dispatch(updateTripOrders({ selectedTripOrder: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_TRIP_ORDER_SUCCESS) {
			let tripOrders = getState().tripOrder.tripOrders;
			tripOrders.push(action.payload);
			dispatch(updateTripOrders({ tripOrders }));
			dispatch(closeCreateTripOrderDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "TripOrder Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_TRIP_ORDER_SUCCESS) {
			let tripOrders = getState().tripOrder.tripOrders;
			let updatedTripOrders = tripOrders.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(
				updateTripOrders({
					tripOrders: updatedTripOrders,
					selectedTripOrder: null,
				})
			);
			dispatch(closeUpdateTripOrderDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "TripOrder Updated",
				})
			);
		}

		if (action.type === DELETE_TRIP_ORDER_SUCCESS) {
			let TripOrders = getState().TripOrder.TripOrders;
			let updatedTripOrders = TripOrders.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateTripOrders({ TripOrders: updatedTripOrders }));
			dispatch(closeUpdateTripOrderDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "TripOrder Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const tripOrderMdl = [getTripOrdersFlow, processTripOrdersFlow];
