import {
	CREATE_VOUCHER,
	DELETE_VOUCHER,
	GET_VOUCHER,
	GET_VOUCHERS,
	UPDATE_VOUCHER,
	UPDATE_VOUCHERS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateVoucherDrawer() {
	return {
		type: UPDATE_VOUCHERS,
		payload: { createVoucherDrawer: true },
	};
}

export function closeCreateVoucherDrawer() {
	return {
		type: UPDATE_VOUCHERS,
		payload: { createVoucherDrawer: false },
	};
}

export function openUpdateVoucherDrawer() {
	return {
		type: UPDATE_VOUCHERS,
		payload: { updateVoucherDrawer: true },
	};
}

export function closeUpdateVoucherDrawer() {
	return {
		type: UPDATE_VOUCHERS,
		payload: { updateVoucherDrawer: false },
	};
}

export function openReadVoucherDrawer() {
	return {
		type: UPDATE_VOUCHERS,
		payload: { readVoucherDrawer: true },
	};
}

export function closeReadVoucherDrawer() {
	return {
		type: UPDATE_VOUCHERS,
		payload: { readVoucherDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllVouchers = () => {
	return {
		type: GET_VOUCHERS,
	};
};

export const getSelectedVoucher = (payload) => {
	return {
		type: GET_VOUCHER,
		payload,
	};
};

export const createNewVoucher = (payload) => {
	return {
		type: CREATE_VOUCHER,
		payload,
	};
};

export const updateVoucher = (payload) => {
	return {
		type: UPDATE_VOUCHER,
		payload,
	};
};

export const deleteVoucher = (payload) => {
	return {
		type: DELETE_VOUCHER,
		payload,
	};
};

export const updateVouchers = (data) => {
	return { type: UPDATE_VOUCHERS, payload: data };
};
