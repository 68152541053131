import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateLeads,
} from "../actions";
import { closeCreateLeadDrawer, closeUpdateLeadDrawer } from "../actions/lead";
import {
	CREATE_LEAD,
	CREATE_LEAD_SUCCESS,
	DELETE_LEAD,
	DELETE_LEAD_SUCCESS,
	GET_LEAD,
	GET_LEADS,
	GET_LEADS_SUCCESS,
	GET_LEAD_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_LEAD,
	UPDATE_LEAD_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All leads -----------------------------------------------------------------
export const getLeadsFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_LEADS) {
			dispatch(updateSpinner({ show: true, message: "Loading Leads . . . " }));

			dispatch(
				listRequest(
					"listLeads",
					null,
					GET_LEADS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_LEAD) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(updateSpinner({ show: true, message: "Loading Lead . . . " }));

			dispatch(
				getSingleRequest(
					"getLead",
					action.payload,
					GET_LEAD_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_LEAD) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Lead . . . ",
				})
			);

			dispatch(
				updateRequest(
					"createLead",
					action.payload,
					CREATE_LEAD_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_LEAD) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(updateSpinner({ show: true, message: "Updating Lead . . . " }));

			dispatch(
				updateRequest(
					"updateLead",
					action.payload,
					UPDATE_LEAD_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_LEAD) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(updateSpinner({ show: true, message: "Deleting Lead . . . " }));
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_LEAD_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processLeadsFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_LEADS_SUCCESS) {
			dispatch(updateLeads({ leads: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_LEAD_SUCCESS) {
			dispatch(updateLeads({ selectedLead: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_LEAD_SUCCESS) {
			let leads = getState().lead.leads;
			leads.push(action.payload);
			dispatch(updateLeads({ leads }));
			dispatch(closeCreateLeadDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Lead Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_LEAD_SUCCESS) {
			let leads = getState().lead.leads;
			let updatedleads = leads.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateLeads({ leads: updatedleads }));
			dispatch(closeUpdateLeadDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "lead Updated",
				})
			);
		}

		if (action.type === DELETE_LEAD_SUCCESS) {
			let leads = getState().lead.leads;
			let updatedleads = leads.filter((el) => el.id !== action.deleteId);
			dispatch(updateLeads({ leads: updatedleads }));
			dispatch(closeUpdateLeadDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "lead Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const leadMdl = [getLeadsFlow, processLeadsFlow];
