import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateFaqs,
} from "../actions";
import { closeCreateFaqDrawer, closeUpdateFaqDrawer } from "../actions/faq";
import {
	CREATE_FAQ,
	CREATE_FAQ_SUCCESS,
	DELETE_FAQ,
	DELETE_FAQ_SUCCESS,
	GET_FAQ,
	GET_FAQS,
	GET_FAQS_SUCCESS,
	GET_FAQ_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_FAQ,
	UPDATE_FAQ_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Faqs -----------------------------------------------------------------
export const getFaqsFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_FAQS) {
			dispatch(updateSpinner({ show: true, message: "Loading Faqs . . . " }));
			dispatch(
				listRequest("listFAQS", null, GET_FAQS_SUCCESS, UPDATE_NOTIFICATION_API)
			);
		}
		if (action.type === GET_FAQ) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(updateSpinner({ show: true, message: "Loading Faq . . . " }));
			dispatch(
				getSingleRequest(
					"getFAQ",
					action.payload,
					GET_FAQ_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_FAQ) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Faq . . . ",
				})
			);
			dispatch(
				updateRequest(
					"createFAQ",
					action.payload,
					CREATE_FAQ_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_FAQ) {
			dispatch(updateSpinner({ show: true, message: "Updating Faq . . . " }));
			dispatch(
				updateRequest(
					"updateFAQ",
					action.payload,
					UPDATE_FAQ_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_FAQ) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(updateSpinner({ show: true, message: "Deleting Faq . . . " }));
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_FAQ_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processFaqsFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_FAQS_SUCCESS) {
			dispatch(updateFaqs({ faqs: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_FAQ_SUCCESS) {
			dispatch(updateFaqs({ selectedFaq: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_FAQ_SUCCESS) {
			let faqs = getState().faq.faqs;
			faqs.push(action.payload);
			dispatch(updateFaqs({ faqs }));
			dispatch(closeCreateFaqDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Faq Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_FAQ_SUCCESS) {
			let faqs = getState().faq.faqs;
			let updatedFaqs = faqs.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateFaqs({ faqs: updatedFaqs, selectedFaq: null }));
			dispatch(closeUpdateFaqDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Faq Updated",
				})
			);
		}

		if (action.type === DELETE_FAQ_SUCCESS) {
			let faqs = getState().faq.faqs;
			let updatedFaqs = faqs.filter((el) => el.id !== action.deleteId);
			dispatch(updateFaqs({ faqs: updatedFaqs }));
			dispatch(closeUpdateFaqDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Faq Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const faqMdl = [getFaqsFlow, processFaqsFlow];
