import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateLeadPackages,
} from "../actions";
import {
	closeCreateLeadPackageDrawer,
	closeUpdateLeadPackageDrawer,
} from "../actions/leadPackage";
import {
	CREATE_LEAD_PACKAGE,
	CREATE_LEAD_PACKAGE_SUCCESS,
	DELETE_LEAD_PACKAGE,
	DELETE_LEAD_PACKAGE_SUCCESS,
	GET_LEAD_PACKAGE,
	GET_LEAD_PACKAGES,
	GET_LEAD_PACKAGES_SUCCESS,
	GET_LEAD_PACKAGE_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_LEAD_PACKAGE,
	UPDATE_LEAD_PACKAGE_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All LeadPackages -----------------------------------------------------------------
export const getLeadPackagesFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_LEAD_PACKAGES) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Lead Packages . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		URL,
			// 		null,
			// 		GET_LEAD_PACKAGES_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );

			dispatch(
				listRequest(
					"listLeadPackages",
					null,
					GET_LEAD_PACKAGES_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_LEAD_PACKAGE) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading Lead Package . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		newURL,
			// 		null,
			// 		GET_LEAD_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			// dispatch(
			// 	listRequest(
			// 		"listEmployees",
			// 		null,
			// 		GET_LEAD_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				getSingleRequest(
					"getLeadPackage",
					action.payload,
					GET_LEAD_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_LEAD_PACKAGE) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Lead Package . . . ",
				})
			);
			// dispatch(
			// 	apiRequest(
			// 		"POST",
			// 		URL,
			// 		action.payload,
			// 		CREATE_LEAD_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				updateRequest(
					"createLeadPackage",
					action.payload,
					CREATE_LEAD_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_LEAD_PACKAGE) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({ show: true, message: "Updating Lead Package . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"PATCH",
			// 		newURL,
			// 		action.payload,
			// 		UPDATE_LEAD_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				updateRequest(
					"updateLeadPackage",
					action.payload,
					UPDATE_LEAD_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_LEAD_PACKAGE) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Lead Package . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_LEAD_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processLeadPackagesFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_LEAD_PACKAGES_SUCCESS) {
			dispatch(updateLeadPackages({ leadPackages: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_LEAD_PACKAGE_SUCCESS) {
			dispatch(updateLeadPackages({ selectedLeadPackage: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_LEAD_PACKAGE_SUCCESS) {
			let leadPackages = getState().leadPackage.leadPackages;
			leadPackages.push(action.payload);
			dispatch(updateLeadPackages({ leadPackages }));
			dispatch(closeCreateLeadPackageDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Lead Package Created Successfully",
				})
			);
		}

		if (action.type === UPDATE_LEAD_PACKAGE_SUCCESS) {
			let leadPackages = getState().leadPackage.leadPackages;
			let updatedLeadPackages = leadPackages.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(
				updateLeadPackages({
					leadPackages: updatedLeadPackages,
					selectedLeadPackage: null,
				})
			);
			dispatch(closeUpdateLeadPackageDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Lead Package Updated",
				})
			);
		}

		if (action.type === DELETE_LEAD_PACKAGE_SUCCESS) {
			let LeadPackages = getState().LeadPackage.LeadPackages;
			let updatedLeadPackages = LeadPackages.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateLeadPackages({ leadPackages: updatedLeadPackages }));
			dispatch(closeUpdateLeadPackageDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Lead Package Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const leadPackageMdl = [getLeadPackagesFlow, processLeadPackagesFlow];
