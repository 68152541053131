import { accommodationMdl } from "./accommodation";
import { activityMdl } from "./activity";
import { API } from "./api";
import { graphqlMdl } from "./graphql";
import { dayMdl } from "./day";
import { NOTIFY } from "./notification";
import { serviceMdl } from "./service";
import { transportationMdl } from "./transportation";
import { demoPackageMdl } from "./demoPackage";
import { leadPackageMdl } from "./leadPackage";
import { leadMdl } from "./lead";
import { destinationMdl } from "./destination";
import { employeeMdl } from "./employee";
import { roleMdl } from "./role";
import { faqMdl } from "./faq";
import { policyMdl } from "./policy";
import { userMdl } from "./user";
import { paymentMdl } from "./payment";
import { flightOrderMdl } from "./flightOrder";
import { tripOrderMdl } from "./tripOrder";
import { dmcMdl } from "./dmcAc";
import { voucherMdl } from "./voucher";
import { companyInfoMdl } from "./companyInfo";
const commonMiddleWare = [
	...activityMdl,
	...dayMdl,
	...serviceMdl,
	...accommodationMdl,
	...transportationMdl,
	...demoPackageMdl,
	...leadPackageMdl,
	...leadMdl,
	...destinationMdl,
	...employeeMdl,
	...roleMdl,
	...faqMdl,
	...policyMdl,
	...userMdl,
	...paymentMdl,
	...flightOrderMdl,
	...tripOrderMdl,
	...graphqlMdl,
	...dmcMdl,
	...voucherMdl,
	...companyInfoMdl,
	API,
	NOTIFY,
];
export default commonMiddleWare;
