import {
	CREATE_DEMO_PACKAGE,
	DELETE_DEMO_PACKAGE,
	GET_DEMO_PACKAGE,
	GET_DEMO_PACKAGES,
	UPDATE_DEMO_PACKAGE,
	UPDATE_DEMO_PACKAGES,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateDemoPackageDrawer() {
	return {
		type: UPDATE_DEMO_PACKAGES,
		payload: { createDemoPackageDrawer: true },
	};
}

export function closeCreateDemoPackageDrawer() {
	return {
		type: UPDATE_DEMO_PACKAGES,
		payload: { createDemoPackageDrawer: false },
	};
}

export function openUpdateDemoPackageDrawer() {
	return {
		type: UPDATE_DEMO_PACKAGES,
		payload: { updateDemoPackageDrawer: true },
	};
}

export function closeUpdateDemoPackageDrawer() {
	return {
		type: UPDATE_DEMO_PACKAGES,
		payload: { updateDemoPackageDrawer: false },
	};
}

export function openReadDemoPackageDrawer() {
	return {
		type: UPDATE_DEMO_PACKAGES,
		payload: { readDemoPackageDrawer: true },
	};
}

export function closeReadDemoPackageDrawer() {
	return {
		type: UPDATE_DEMO_PACKAGES,
		payload: { readDemoPackageDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllDemoPackages = () => {
	return {
		type: GET_DEMO_PACKAGES,
	};
};

export const getSelectedDemoPackage = (payload) => {
	return {
		type: GET_DEMO_PACKAGE,
		payload,
	};
};

export const createNewDemoPackage = (payload) => {
	return {
		type: CREATE_DEMO_PACKAGE,
		payload,
	};
};

export const updateDemoPackage = (payload) => {
	return {
		type: UPDATE_DEMO_PACKAGE,
		payload,
	};
};

export const deleteDemoPackage = (payload) => {
	return {
		type: DELETE_DEMO_PACKAGE,
		payload,
	};
};

export const updateDemoPackages = (data) => {
	return { type: UPDATE_DEMO_PACKAGES, payload: data };
};
