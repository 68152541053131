import {
	CREATE_LEAD_PACKAGE,
	DELETE_LEAD_PACKAGE,
	GET_LEAD_PACKAGE,
	GET_LEAD_PACKAGES,
	UPDATE_LEAD_PACKAGE,
	UPDATE_LEAD_PACKAGES,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateLeadPackageDrawer() {
	return {
		type: UPDATE_LEAD_PACKAGES,
		payload: { createLeadPackageDrawer: true },
	};
}

export function closeCreateLeadPackageDrawer() {
	return {
		type: UPDATE_LEAD_PACKAGES,
		payload: { createLeadPackageDrawer: false },
	};
}

export function openUpdateLeadPackageDrawer() {
	return {
		type: UPDATE_LEAD_PACKAGES,
		payload: { updateLeadPackageDrawer: true },
	};
}

export function closeUpdateLeadPackageDrawer() {
	return {
		type: UPDATE_LEAD_PACKAGES,
		payload: { updateLeadPackageDrawer: false },
	};
}

export function openReadLeadPackageDrawer() {
	return {
		type: UPDATE_LEAD_PACKAGES,
		payload: { readLeadPackageDrawer: true },
	};
}

export function closeReadLeadPackageDrawer() {
	return {
		type: UPDATE_LEAD_PACKAGES,
		payload: { readLeadPackageDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllLeadPackages = () => {
	return {
		type: GET_LEAD_PACKAGES,
	};
};

export const getSelectedLeadPackage = (payload) => {
	return {
		type: GET_LEAD_PACKAGE,
		payload,
	};
};

export const createNewLeadPackage = (payload) => {
	return {
		type: CREATE_LEAD_PACKAGE,
		payload,
	};
};

export const updateLeadPackage = (payload) => {
	return {
		type: UPDATE_LEAD_PACKAGE,
		payload,
	};
};

export const deleteLeadPackage = (payload) => {
	return {
		type: DELETE_LEAD_PACKAGE,
		payload,
	};
};

export const updateLeadPackages = (data) => {
	return { type: UPDATE_LEAD_PACKAGES, payload: data };
};
