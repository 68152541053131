import { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import App from "./App";
import awsExports from "./aws-exports";
import "./index.css";
import AbilityProvider from "./providers/AbilityProvider";
import ReduxProvider from "./providers/ReduxProvider";
import reportWebVitals from "./reportWebVitals";

// if (process.env.NODE_ENV !== "production") {
// 	console.log = () => {};
// 	console.error = () => {};
// 	console.debug = () => {};
// }
const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
	awsExports.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
	awsExports.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
	...awsExports,
	oauth: {
		...awsExports.oauth,
		redirectSignIn: isLocalhost
			? localRedirectSignIn
			: productionRedirectSignIn,
		redirectSignOut: isLocalhost
			? localRedirectSignOut
			: productionRedirectSignOut,
	},
};

Amplify.configure(updatedAwsConfig);

const script = document.createElement("script");
script.src = "https://checkout.razorpay.com/v1/checkout.js";

document.body.appendChild(script);

ReactDOM.render(
	<ReduxProvider>
		<AbilityProvider>
			<Suspense fallback={<></>}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Suspense>
		</AbilityProvider>
	</ReduxProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
