import axios from "axios";
import { API_REQUEST } from "../constants";
// This middleware care only for API calls
export const API =
	({ dispatch }) =>
	(next) =>
	async (action) => {
		if (action.type === API_REQUEST) {
			const { payload } = action;
			const { method, url, onSuccess, onError } = action.meta;
			const id = method === "DELETE" ? payload.id : null;
			try {
				const response = await axios({
					method,
					url,
					data: payload,
				});
				dispatch({ type: onSuccess, payload: response.data, deleteId: id });
			} catch (error) {
				const [a, b, ...c] = onSuccess.split(" ")[1].split("_");
				let errorMessage = `${a} ${b} ERROR`;
				dispatch({
					type: onError,
					payload: {
						type: "error",
						message: errorMessage || "Error",
						description: error.message,
					},
				});
			}
		}
		return next(action);
	};
