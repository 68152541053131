import {
	CREATE_FLIGHT_ORDER,
	DELETE_FLIGHT_ORDER,
	GET_FLIGHT_ORDER,
	GET_FLIGHT_ORDERS,
	UPDATE_FLIGHT_ORDER,
	UPDATE_FLIGHT_ORDERS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateFlightOrderDrawer() {
	return {
		type: UPDATE_FLIGHT_ORDERS,
		payload: { createFlightOrderDrawer: true },
	};
}

export function closeCreateFlightOrderDrawer() {
	return {
		type: UPDATE_FLIGHT_ORDERS,
		payload: { createFlightOrderDrawer: false },
	};
}

export function openUpdateFlightOrderDrawer() {
	return {
		type: UPDATE_FLIGHT_ORDERS,
		payload: { updateFlightOrderDrawer: true },
	};
}

export function closeUpdateFlightOrderDrawer() {
	return {
		type: UPDATE_FLIGHT_ORDERS,
		payload: { updateFlightOrderDrawer: false },
	};
}

export function openReadFlightOrderDrawer() {
	return {
		type: UPDATE_FLIGHT_ORDERS,
		payload: { readFlightOrderDrawer: true },
	};
}

export function closeReadFlightOrderDrawer() {
	return {
		type: UPDATE_FLIGHT_ORDERS,
		payload: { readFlightOrderDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllFlightOrders = () => {
	return {
		type: GET_FLIGHT_ORDERS,
	};
};

export const getSelectedFlightOrder = (payload) => {
	return {
		type: GET_FLIGHT_ORDER,
		payload,
	};
};

export const createNewFlightOrder = (payload) => {
	return {
		type: CREATE_FLIGHT_ORDER,
		payload,
	};
};

export const updateFlightOrder = (payload) => {
	return {
		type: UPDATE_FLIGHT_ORDER,
		payload,
	};
};

export const deleteFlightOrder = (payload) => {
	return {
		type: DELETE_FLIGHT_ORDER,
		payload,
	};
};

export const updateFlightOrders = (data) => {
	return { type: UPDATE_FLIGHT_ORDERS, payload: data };
};
