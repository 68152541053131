import { UPDATE_DESTINATIONS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_DESTINATIONS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createDestinationDrawer: false,
	updateDestinationDrawer: false,
	readDestinationDrawer: false,
	destinations: [],
	selectedDestination: null,
	authKey: null,
};
export default function destinationReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
