import {
	CREATE_PAYMENT,
	DELETE_PAYMENT,
	GET_PAYMENT,
	GET_PAYMENTS,
	UPDATE_PAYMENT,
	UPDATE_PAYMENTS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreatePaymentDrawer() {
	return {
		type: UPDATE_PAYMENTS,
		payload: { createPaymentDrawer: true },
	};
}

export function closeCreatePaymentDrawer() {
	return {
		type: UPDATE_PAYMENTS,
		payload: { createPaymentDrawer: false },
	};
}

export function openUpdatePaymentDrawer() {
	return {
		type: UPDATE_PAYMENTS,
		payload: { updatePaymentDrawer: true },
	};
}

export function closeUpdatePaymentDrawer() {
	return {
		type: UPDATE_PAYMENTS,
		payload: { updatePaymentDrawer: false },
	};
}

export function openReadPaymentDrawer() {
	return {
		type: UPDATE_PAYMENTS,
		payload: { readPaymentDrawer: true },
	};
}

export function closeReadPaymentDrawer() {
	return {
		type: UPDATE_PAYMENTS,
		payload: { readPaymentDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllPayments = () => {
	return {
		type: GET_PAYMENTS,
	};
};

export const getSelectedPayment = (payload) => {
	return {
		type: GET_PAYMENT,
		payload,
	};
};

export const createNewPayment = (payload) => {
	return {
		type: CREATE_PAYMENT,
		payload,
	};
};

export const updatePayment = (payload) => {
	return {
		type: UPDATE_PAYMENT,
		payload,
	};
};

export const deletePayment = (payload) => {
	return {
		type: DELETE_PAYMENT,
		payload,
	};
};

export const updatePayments = (data) => {
	return { type: UPDATE_PAYMENTS, payload: data };
};
