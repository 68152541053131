import { Ability, AbilityBuilder } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { createContext } from "react";
import Store from "./../../redux/store";

export const AbilityContext = createContext();

export const Can = createContextualCan(AbilityContext.Consumer);

export const ability = new Ability();

export const updateAbility = (isAdmin = false, permissions = []) => {
	// console.log(isAdmin, permissions);
	const { can, rules } = new AbilityBuilder();

	!!isAdmin
		? can("manage", "all")
		: permissions.forEach((p) => {
				let per = p.split("_");
				can(per[1], per[0]);
		  });
	return rules;
};

export default function ({ children }) {
	return (
		<AbilityContext.Provider value={ability}>
			{children}
		</AbilityContext.Provider>
	);
}

Store.subscribe(() => {
	const Employee = Store.getState().employee;
	const { loggedInEmployee, employeeRole } = Employee;
	if (!!loggedInEmployee && !!employeeRole) {
		const rules = updateAbility(
			employeeRole?.isAdmin,
			employeeRole?.permissions
		);
		ability.update(rules);
	}
	// const rules = updateAbility(true, []);
	// ability.update(rules);
});
