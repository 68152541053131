import { UPDATE_FAQS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_FAQS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createFaqDrawer: false,
	updateFaqDrawer: false,
	readFaqDrawer: false,
	faqs: [],
	selectedFaq: null,
	authKey: null,
};
export default function faqReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
