import {
	CREATE_USER,
	DELETE_USER,
	GET_USER,
	GET_USERS,
	UPDATE_USER,
	UPDATE_USERS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateUserDrawer() {
	return {
		type: UPDATE_USERS,
		payload: { createUserDrawer: true },
	};
}

export function closeCreateUserDrawer() {
	return {
		type: UPDATE_USERS,
		payload: { createUserDrawer: false },
	};
}

export function openUpdateUserDrawer() {
	return {
		type: UPDATE_USERS,
		payload: { updateUserDrawer: true },
	};
}

export function closeUpdateUserDrawer() {
	return {
		type: UPDATE_USERS,
		payload: { updateUserDrawer: false },
	};
}

export function openReadUserDrawer() {
	return {
		type: UPDATE_USERS,
		payload: { readUserDrawer: true },
	};
}

export function closeReadUserDrawer() {
	return {
		type: UPDATE_USERS,
		payload: { readUserDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllUsers = () => {
	return {
		type: GET_USERS,
	};
};

export const getSelectedUser = (payload) => {
	return {
		type: GET_USER,
		payload,
	};
};

export const createNewUser = (payload) => {
	return {
		type: CREATE_USER,
		payload,
	};
};

export const updateUser = (payload) => {
	return {
		type: UPDATE_USER,
		payload,
	};
};

export const deleteUser = (payload) => {
	return {
		type: DELETE_USER,
		payload,
	};
};

export const updateUsers = (data) => {
	return { type: UPDATE_USERS, payload: data };
};
