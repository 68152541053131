import {
	CREATE_DESTINATION,
	DELETE_DESTINATION,
	GET_DESTINATION,
	GET_DESTINATIONS,
	UPDATE_DESTINATION,
	UPDATE_DESTINATIONS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateDestinationDrawer() {
	return {
		type: UPDATE_DESTINATIONS,
		payload: { createDestinationDrawer: true },
	};
}

export function closeCreateDestinationDrawer() {
	return {
		type: UPDATE_DESTINATIONS,
		payload: { createDestinationDrawer: false },
	};
}

export function openUpdateDestinationDrawer() {
	return {
		type: UPDATE_DESTINATIONS,
		payload: { updateDestinationDrawer: true },
	};
}

export function closeUpdateDestinationDrawer() {
	return {
		type: UPDATE_DESTINATIONS,
		payload: { updateDestinationDrawer: false, selectedDestination: null },
	};
}

export function openReadDestinationDrawer() {
	return {
		type: UPDATE_DESTINATIONS,
		payload: { readDestinationDrawer: true },
	};
}

export function closeReadDestinationDrawer() {
	return {
		type: UPDATE_DESTINATIONS,
		payload: { readDestinationDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllDestinations = () => {
	return {
		type: GET_DESTINATIONS,
	};
};

export const getSelectedDestination = (payload) => {
	return {
		type: GET_DESTINATION,
		payload,
	};
};

export const createNewDestination = (payload) => {
	return {
		type: CREATE_DESTINATION,
		payload,
	};
};

export const updateDestination = (payload) => {
	return {
		type: UPDATE_DESTINATION,
		payload,
	};
};

export const deleteDestination = (payload) => {
	return {
		type: DELETE_DESTINATION,
		payload,
	};
};

export const updateDestinations = (data) => {
	return { type: UPDATE_DESTINATIONS, payload: data };
};
