import { UPDATE_LEADS } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_LEADS]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createLeadDrawer: false,
	updateLeadDrawer: false,
	readLeadDrawer: false,
	leads: [],
	selectedLead: null,
	authKey: null,
};
export default function leadReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
