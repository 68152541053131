import { combineReducers } from "redux";
import sideBarReducer from "../modules/sideBar";
import spinnerReducer from "../modules/spinner";
import accommodationReducer from "./accommodation";
import activityReducer from "./activity";
import dayReducer from "./day";
import notificationReducer from "./notification";
import serviceReducer from "./service";
import transportationReducer from "./transportation";
import demoPackageReducer from "./demoPackage";
import leadPackageReducer from "./leadPackage";
import leadReducer from "./lead";
import destinationReducer from "./destination";
import employeeReducer from "./employee";
import roleReducer from "./role";
import policyReducer from "./policy";
import faqReducer from "./faq";
import userReducer from "./user";
import paymentReducer from "./payment";
import flightOrderReducer from "./flightOrder";
import tripOrderReducer from "./tripOrder";
import dmcAcReducer from "./dmcAc";
import voucherReducer from "./voucher";
import companyInfoReducer from "./companyInfo";

const reducers = combineReducers({
	sidebar: sideBarReducer,
	notification: notificationReducer,
	spinner: spinnerReducer,
	day: dayReducer,
	activity: activityReducer,
	accommodation: accommodationReducer,
	transportation: transportationReducer,
	demoPackage: demoPackageReducer,
	leadPackage: leadPackageReducer,
	lead: leadReducer,
	service: serviceReducer,
	destination: destinationReducer,
	employee: employeeReducer,
	role: roleReducer,
	policy: policyReducer,
	faq: faqReducer,
	user: userReducer,
	payment: paymentReducer,
	flightOrder: flightOrderReducer,
	tripOrder: tripOrderReducer,
	dmc: dmcAcReducer,
	voucher: voucherReducer,
	companyInfo: companyInfoReducer,
});

export default reducers;
