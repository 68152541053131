export const API_REQUEST = "{APP} API_REQUEST";
export const GET_LIST_QUERY = "{APP} GET_LIST_QUERY";
export const GET_SINGLE_QUERY = "{APP} GET_SINGLE_QUERY";
export const UPDATE_MUTATION = "{APP} UPDATE_MUTATION";
// ---------------------------------------------------------------------------------------------------------------------
export const UPDATE_SPINNER = "{APP} UPDATE_SPINNER";
// ---------------------------------------------------------------------------------------------------------------------
export const TOGGLE_SIDEBAR = "{SIDEBAR} TOGGLE_SIDEBAR";
// ---------------------------------------------------------------------------------------------------------------------
export const UPDATE_NOTIFICATION = "{NOTIFY} UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_API = "{NOTIFY} UPDATE_NOTIFICATION_API";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_SERVICES = "{SERVICE} GET_SERVICES";
export const GET_SERVICES_SUCCESS = "{SERVICE} GET_SERVICES_SUCCESS";
export const GET_SERVICE = "{SERVICE} GET_SERVICE";
export const GET_SERVICE_SUCCESS = "{SERVICE} GET_SERVICE_SUCCESS";
export const CREATE_SERVICE = "{SERVICE} CREATE_SERVICE";
export const CREATE_SERVICE_SUCCESS = "{SERVICE} CREATE_SERVICE_SUCCESS";
export const READ_SERVICE = "{SERVICE} READ_SERVICE";
export const READ_SERVICE_SUCCESS = "{SERVICE} READ_SERVICE_SUCCESS";
export const UPDATE_SERVICE = "{SERVICE} UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "{SERVICE} UPDATE_SERVICE_SUCCESS";
export const DELETE_SERVICE = "{SERVICE} DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "{SERVICE} DELETE_SERVICE_SUCCESS";
export const UPDATE_SERVICES = "{SERVICE} UPDATE_SERVICES";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_DAYS = "{DAY} GET_DAYS";
export const GET_DAYS_SUCCESS = "{DAY} GET_DAYS_SUCCESS";
export const GET_DAY = "{DAY} GET_DAY";
export const GET_DAY_SUCCESS = "{DAY} GET_DAY_SUCCESS";
export const CREATE_DAY = "{DAY} CREATE_DAY";
export const CREATE_DAY_SUCCESS = "{DAY} CREATE_DAY_SUCCESS";
export const READ_DAY = "{DAY} READ_DAY";
export const READ_DAY_SUCCESS = "{DAY} READ_DAY_SUCCESS";
export const UPDATE_DAY = "{DAY} UPDATE_DAY";
export const UPDATE_DAY_SUCCESS = "{DAY} UPDATE_DAY_SUCCESS";
export const DELETE_DAY = "{DAY} DELETE_DAY";
export const DELETE_DAY_SUCCESS = "{DAY} DELETE_DAY_SUCCESS";
export const UPDATE_DAYS = "{DAY} UPDATE_DAYS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_ACCOMMODATIONS = "{ACCOMMODATION} GET_ACCOMMODATIONS";
export const GET_ACCOMMODATIONS_SUCCESS =
	"{ACCOMMODATION} GET_ACCOMMODATIONS_SUCCESS";
export const GET_ACCOMMODATION = "{ACCOMMODATION} GET_ACCOMMODATION";
export const GET_ACCOMMODATION_SUCCESS =
	"{ACCOMMODATION} GET_ACCOMMODATION_SUCCESS";
export const CREATE_ACCOMMODATION = "{ACCOMMODATION} CREATE_ACCOMMODATION";
export const CREATE_ACCOMMODATION_SUCCESS =
	"{ACCOMMODATION} CREATE_ACCOMMODATION_SUCCESS";
export const READ_ACCOMMODATION = "{ACCOMMODATION} READ_ACCOMMODATION";
export const READ_ACCOMMODATION_SUCCESS =
	"{ACCOMMODATION} READ_ACCOMMODATION_SUCCESS";
export const UPDATE_ACCOMMODATION = "{ACCOMMODATION} UPDATE_ACCOMMODATION";
export const UPDATE_ACCOMMODATION_SUCCESS =
	"{ACCOMMODATION} UPDATE_ACCOMMODATION_SUCCESS";
export const DELETE_ACCOMMODATION = "{ACCOMMODATION} DELETE_ACCOMMODATION";
export const DELETE_ACCOMMODATION_SUCCESS =
	"{ACCOMMODATION} DELETE_ACCOMMODATION_SUCCESS";
export const UPDATE_ACCOMMODATIONS = "{ACCOMMODATION} UPDATE_ACCOMMODATIONS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_TRANSPORTATIONS = "{TRANSPORTATION} GET_TRANSPORTATIONS";
export const GET_TRANSPORTATIONS_SUCCESS =
	"{TRANSPORTATION} GET_TRANSPORTATIONS_SUCCESS";
export const GET_TRANSPORTATION = "{TRANSPORTATION} GET_TRANSPORTATION";
export const GET_TRANSPORTATION_SUCCESS =
	"{TRANSPORTATION} GET_TRANSPORTATION_SUCCESS";
export const CREATE_TRANSPORTATION = "{TRANSPORTATION} CREATE_TRANSPORTATION";
export const CREATE_TRANSPORTATION_SUCCESS =
	"{TRANSPORTATION} CREATE_TRANSPORTATION_SUCCESS";
export const READ_TRANSPORTATION = "{TRANSPORTATION} READ_TRANSPORTATION";
export const READ_TRANSPORTATION_SUCCESS =
	"{TRANSPORTATION} READ_TRANSPORTATION_SUCCESS";
export const UPDATE_TRANSPORTATION = "{TRANSPORTATION} UPDATE_TRANSPORTATION";
export const UPDATE_TRANSPORTATION_SUCCESS =
	"{TRANSPORTATION} UPDATE_TRANSPORTATION_SUCCESS";
export const DELETE_TRANSPORTATION = "{TRANSPORTATION} DELETE_TRANSPORTATION";
export const DELETE_TRANSPORTATION_SUCCESS =
	"{TRANSPORTATION} DELETE_TRANSPORTATION_SUCCESS";
export const UPDATE_TRANSPORTATIONS = "{TRANSPORTATION} UPDATE_TRANSPORTATIONS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_DEMO_PACKAGES = "{STATIC-PACKAGE} GET_STATIC-PACKAGES";
export const GET_DEMO_PACKAGES_SUCCESS =
	"{STATIC-PACKAGE} GET_STATIC-PACKAGES_SUCCESS";
export const GET_DEMO_PACKAGE = "{STATIC-PACKAGE} GET_STATIC-PACKAGE";
export const GET_DEMO_PACKAGE_SUCCESS =
	"{STATIC-PACKAGE} GET_STATIC-PACKAGE_SUCCESS";
export const CREATE_DEMO_PACKAGE = "{STATIC-PACKAGE} CREATE_STATIC-PACKAGE";
export const CREATE_DEMO_PACKAGE_SUCCESS =
	"{STATIC-PACKAGE} CREATE_STATIC-PACKAGE_SUCCESS";
export const READ_DEMO_PACKAGE = "{STATIC-PACKAGE} READ_STATIC-PACKAGE";
export const READ_DEMO_PACKAGE_SUCCESS =
	"{STATIC-PACKAGE} READ_STATIC-PACKAGE_SUCCESS";
export const UPDATE_DEMO_PACKAGE = "{STATIC-PACKAGE} UPDATE_STATIC-PACKAGE";
export const UPDATE_DEMO_PACKAGE_SUCCESS =
	"{STATIC-PACKAGE} UPDATE_STATIC-PACKAGE_SUCCESS";
export const DELETE_DEMO_PACKAGE = "{STATIC-PACKAGE} DELETE_STATIC-PACKAGE";
export const DELETE_DEMO_PACKAGE_SUCCESS =
	"{STATIC-PACKAGE} DELETE_STATIC-PACKAGE_SUCCESS";
export const UPDATE_DEMO_PACKAGES = "{STATIC-PACKAGE} UPDATE_STATIC-PACKAGES";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_LEAD_PACKAGES = "{LEAD-PACKAGE} GET_LEAD-PACKAGES";
export const GET_LEAD_PACKAGES_SUCCESS =
	"{LEAD-PACKAGE} GET_LEAD-PACKAGES_SUCCESS";
export const GET_LEAD_PACKAGE = "{LEAD-PACKAGE} GET_LEAD-PACKAGE";
export const GET_LEAD_PACKAGE_SUCCESS =
	"{LEAD-PACKAGE} GET_LEAD-PACKAGE_SUCCESS";
export const CREATE_LEAD_PACKAGE = "{LEAD-PACKAGE} CREATE_LEAD-PACKAGE";
export const CREATE_LEAD_PACKAGE_SUCCESS =
	"{LEAD-PACKAGE} CREATE_LEAD-PACKAGE_SUCCESS";
export const READ_LEAD_PACKAGE = "{LEAD-PACKAGE} READ_LEAD-PACKAGE";
export const READ_LEAD_PACKAGE_SUCCESS =
	"{LEAD-PACKAGE} READ_LEAD-PACKAGE_SUCCESS";
export const UPDATE_LEAD_PACKAGE = "{LEAD-PACKAGE} UPDATE_LEAD-PACKAGE";
export const UPDATE_LEAD_PACKAGE_SUCCESS =
	"{LEAD-PACKAGE} UPDATE_LEAD-PACKAGE_SUCCESS";
export const DELETE_LEAD_PACKAGE = "{LEAD-PACKAGE} DELETE_LEAD-PACKAGE";
export const DELETE_LEAD_PACKAGE_SUCCESS =
	"{LEAD-PACKAGE} DELETE_LEAD-PACKAGE_SUCCESS";
export const UPDATE_LEAD_PACKAGES = "{LEAD-PACKAGE} UPDATE_LEAD-PACKAGES";

// ---------------------------------------------------------------------------------------------------------------------
export const GET_LEADS = "{LEAD} GET_LEADS";
export const GET_LEADS_SUCCESS = "{LEAD} GET_LEADS_SUCCESS";
export const GET_LEAD = "{LEAD} GET_LEAD";
export const GET_LEAD_SUCCESS = "{LEAD} GET_LEAD_SUCCESS";
export const CREATE_LEAD = "{LEAD} CREATE_LEAD";
export const CREATE_LEAD_SUCCESS = "{LEAD} CREATE_LEAD_SUCCESS";
export const READ_LEAD = "{LEAD} READ_LEAD";
export const READ_LEAD_SUCCESS = "{LEAD} READ_LEAD_SUCCESS";
export const UPDATE_LEAD = "{LEAD} UPDATE_LEAD";
export const UPDATE_LEAD_SUCCESS = "{LEAD} UPDATE_LEAD_SUCCESS";
export const DELETE_LEAD = "{LEAD} DELETE_LEAD";
export const DELETE_LEAD_SUCCESS = "{LEAD} DELETE_LEAD_SUCCESS";
export const UPDATE_LEADS = "{LEAD} UPDATE_LEADS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_DESTINATIONS = "{DESTINATION} GET_DESTINATIONS";
export const GET_DESTINATIONS_SUCCESS =
	"{DESTINATION} GET_DESTINATIONS_SUCCESS";
export const GET_DESTINATION = "{DESTINATION} GET_DESTINATION";
export const GET_DESTINATION_SUCCESS = "{DESTINATION} GET_DESTINATION_SUCCESS";
export const CREATE_DESTINATION = "{DESTINATION} CREATE_DESTINATION";
export const CREATE_DESTINATION_SUCCESS =
	"{DESTINATION} CREATE_DESTINATION_SUCCESS";
export const READ_DESTINATION = "{DESTINATION} READ_DESTINATION";
export const READ_DESTINATION_SUCCESS =
	"{DESTINATION} READ_DESTINATION_SUCCESS";
export const UPDATE_DESTINATION = "{DESTINATION} UPDATE_DESTINATION";
export const UPDATE_DESTINATION_SUCCESS =
	"{DESTINATION} UPDATE_DESTINATION_SUCCESS";
export const DELETE_DESTINATION = "{DESTINATION} DELETE_DESTINATION";
export const DELETE_DESTINATION_SUCCESS =
	"{DESTINATION} DELETE_DESTINATION_SUCCESS";
export const UPDATE_DESTINATIONS = "{DESTINATION} UPDATE_DESTINATIONS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_EMPLOYEES = "{EMPLOYEE} GET_EMPLOYEES";
export const GET_EMPLOYEES_SUCCESS = "{EMPLOYEE} GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEE = "{EMPLOYEE} GET_EMPLOYEE";
export const GET_EMPLOYEE_SUCCESS = "{EMPLOYEE} GET_EMPLOYEE_SUCCESS";
export const GET_LOGIN_EMPLOYEE = "{EMPLOYEE} GET_LOGIN_EMPLOYEE";
export const GET_LOGIN_EMPLOYEE_SUCCESS =
	"{EMPLOYEE} GET_LOGIN_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE = "{EMPLOYEE} CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_SUCCESS = "{EMPLOYEE} CREATE_EMPLOYEE_SUCCESS";
export const READ_EMPLOYEE = "{EMPLOYEE} READ_EMPLOYEE";
export const READ_EMPLOYEE_SUCCESS = "{EMPLOYEE} READ_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE = "{EMPLOYEE} UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "{EMPLOYEE} UPDATE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE = "{EMPLOYEE} DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SUCCESS = "{EMPLOYEE} DELETE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEES = "{EMPLOYEE} UPDATE_EMPLOYEES";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_ROLES = "{ROLE} GET_ROLES";
export const GET_ROLES_SUCCESS = "{ROLE} GET_ROLES_SUCCESS";
export const GET_ROLE = "{ROLE} GET_ROLE";
export const GET_ROLE_SUCCESS = "{ROLE} GET_ROLE_SUCCESS";
export const CREATE_ROLE = "{ROLE} CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "{ROLE} CREATE_ROLE_SUCCESS";
export const READ_ROLE = "{ROLE} READ_ROLE";
export const READ_ROLE_SUCCESS = "{ROLE} READ_ROLE_SUCCESS";
export const UPDATE_ROLE = "{ROLE} UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "{ROLE} UPDATE_ROLE_SUCCESS";
export const DELETE_ROLE = "{ROLE} DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "{ROLE} DELETE_ROLE_SUCCESS";
export const UPDATE_ROLES = "{ROLE} UPDATE_ROLES";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_ACTIVITIES = "{ACTIVITY} GET_ACTIVITIES";
export const GET_ACTIVITIES_SUCCESS = "{ACTIVITY} GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITY = "{ACTIVITY} GET_ACTIVITY";
export const GET_ACTIVITY_SUCCESS = "{ACTIVITY} GET_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY = "{ACTIVITY} CREATE_ACTIVITY";
export const CREATE_ACTIVITY_SUCCESS = "{ACTIVITY} CREATE_ACTIVITY_SUCCESS";
export const READ_ACTIVITY = "{ACTIVITY} READ_ACTIVITY";
export const READ_ACTIVITY_SUCCESS = "{ACTIVITY} READ_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY = "{ACTIVITY} UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_SUCCESS = "{ACTIVITY} UPDATE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY = "{ACTIVITY} DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "{ACTIVITY} DELETE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITIES = "{ACTIVITY} UPDATE_ACTIVITIES";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_USERS = "{USER} GET_USERS";
export const GET_USERS_SUCCESS = "{USER} GET_USERS_SUCCESS";
export const GET_USER = "{USER} GET_USER";
export const GET_USER_SUCCESS = "{USER} GET_USER_SUCCESS";
export const CREATE_USER = "{USER} CREATE_USER";
export const CREATE_USER_SUCCESS = "{USER} CREATE_USER_SUCCESS";
export const READ_USER = "{USER} READ_USER";
export const READ_USER_SUCCESS = "{USER} READ_USER_SUCCESS";
export const UPDATE_USER = "{USER} UPDATE_USER";
export const UPDATE_USER_SUCCESS = "{USER} UPDATE_USER_SUCCESS";
export const DELETE_USER = "{USER} DELETE_USER";
export const DELETE_USER_SUCCESS = "{USER} DELETE_USER_SUCCESS";
export const UPDATE_USERS = "{USER} UPDATE_USERS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_PAYMENTS = "{PAYMENT} GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "{PAYMENT} GET_PAYMENTS_SUCCESS";
export const GET_PAYMENT = "{PAYMENT} GET_PAYMENT";
export const GET_PAYMENT_SUCCESS = "{PAYMENT} GET_PAYMENT_SUCCESS";
export const CREATE_PAYMENT = "{PAYMENT} CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "{PAYMENT} CREATE_PAYMENT_SUCCESS";
export const READ_PAYMENT = "{PAYMENT} READ_PAYMENT";
export const READ_PAYMENT_SUCCESS = "{PAYMENT} READ_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT = "{PAYMENT} UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "{PAYMENT} UPDATE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT = "{PAYMENT} DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "{PAYMENT} DELETE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENTS = "{PAYMENT} UPDATE_PAYMENTS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_FLIGHT_ORDERS = "{FLIGHT_ORDER} GET_FLIGHT_ORDERS";
export const GET_FLIGHT_ORDERS_SUCCESS =
	"{FLIGHT_ORDER} GET_FLIGHT_ORDERS_SUCCESS";
export const GET_FLIGHT_ORDER = "{FLIGHT_ORDER} GET_FLIGHT_ORDER";
export const GET_FLIGHT_ORDER_SUCCESS =
	"{FLIGHT_ORDER} GET_FLIGHT_ORDER_SUCCESS";
export const CREATE_FLIGHT_ORDER = "{FLIGHT_ORDER} CREATE_FLIGHT_ORDER";
export const CREATE_FLIGHT_ORDER_SUCCESS =
	"{FLIGHT_ORDER} CREATE_FLIGHT_ORDER_SUCCESS";
export const READ_FLIGHT_ORDER = "{FLIGHT_ORDER} READ_FLIGHT_ORDER";
export const READ_FLIGHT_ORDER_SUCCESS =
	"{FLIGHT_ORDER} READ_FLIGHT_ORDER_SUCCESS";
export const UPDATE_FLIGHT_ORDER = "{FLIGHT_ORDER} UPDATE_FLIGHT_ORDER";
export const UPDATE_FLIGHT_ORDER_SUCCESS =
	"{FLIGHT_ORDER} UPDATE_FLIGHT_ORDER_SUCCESS";
export const DELETE_FLIGHT_ORDER = "{FLIGHT_ORDER} DELETE_FLIGHT_ORDER";
export const DELETE_FLIGHT_ORDER_SUCCESS =
	"{FLIGHT_ORDER} DELETE_FLIGHT_ORDER_SUCCESS";
export const UPDATE_FLIGHT_ORDERS = "{FLIGHT_ORDER} UPDATE_FLIGHT_ORDERS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_TRIP_ORDERS = "{TRIP_ORDER} GET_TRIP_ORDERS";
export const GET_TRIP_ORDERS_SUCCESS = "{TRIP_ORDER} GET_TRIP_ORDERS_SUCCESS";
export const GET_TRIP_ORDER = "{TRIP_ORDER} GET_TRIP_ORDER";
export const GET_TRIP_ORDER_SUCCESS = "{TRIP_ORDER} GET_TRIP_ORDER_SUCCESS";
export const CREATE_TRIP_ORDER = "{TRIP_ORDER} CREATE_TRIP_ORDER";
export const CREATE_TRIP_ORDER_SUCCESS =
	"{TRIP_ORDER} CREATE_TRIP_ORDER_SUCCESS";
export const READ_TRIP_ORDER = "{TRIP_ORDER} READ_TRIP_ORDER";
export const READ_TRIP_ORDER_SUCCESS = "{TRIP_ORDER} READ_TRIP_ORDER_SUCCESS";
export const UPDATE_TRIP_ORDER = "{TRIP_ORDER} UPDATE_TRIP_ORDER";
export const UPDATE_TRIP_ORDER_SUCCESS =
	"{TRIP_ORDER} UPDATE_TRIP_ORDER_SUCCESS";
export const DELETE_TRIP_ORDER = "{TRIP_ORDER} DELETE_TRIP_ORDER";
export const DELETE_TRIP_ORDER_SUCCESS =
	"{TRIP_ORDER} DELETE_TRIP_ORDER_SUCCESS";
export const UPDATE_TRIP_ORDERS = "{TRIP_ORDER} UPDATE_TRIP_ORDERS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_POLICYS = "{POLICY} GET_POLICYS";
export const GET_POLICYS_SUCCESS = "{POLICY} GET_POLICYS_SUCCESS";
export const GET_POLICY = "{POLICY} GET_POLICY";
export const GET_POLICY_SUCCESS = "{POLICY} GET_POLICY_SUCCESS";
export const CREATE_POLICY = "{POLICY} CREATE_POLICY";
export const CREATE_POLICY_SUCCESS = "{POLICY} CREATE_POLICY_SUCCESS";
export const READ_POLICY = "{POLICY} READ_POLICY";
export const READ_POLICY_SUCCESS = "{POLICY} READ_POLICY_SUCCESS";
export const UPDATE_POLICY = "{POLICY} UPDATE_POLICY";
export const UPDATE_POLICY_SUCCESS = "{POLICY} UPDATE_POLICY_SUCCESS";
export const DELETE_POLICY = "{POLICY} DELETE_POLICY";
export const DELETE_POLICY_SUCCESS = "{POLICY} DELETE_POLICY_SUCCESS";
export const UPDATE_POLICYS = "{POLICY} UPDATE_POLICYS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_FAQS = "{FAQ} GET_FAQS";
export const GET_FAQS_SUCCESS = "{FAQ} GET_FAQS_SUCCESS";
export const GET_FAQ = "{FAQ} GET_FAQ";
export const GET_FAQ_SUCCESS = "{FAQ} GET_FAQ_SUCCESS";
export const CREATE_FAQ = "{FAQ} CREATE_FAQ";
export const CREATE_FAQ_SUCCESS = "{FAQ} CREATE_FAQ_SUCCESS";
export const READ_FAQ = "{FAQ} READ_FAQ";
export const READ_FAQ_SUCCESS = "{FAQ} READ_FAQ_SUCCESS";
export const UPDATE_FAQ = "{FAQ} UPDATE_FAQ";
export const UPDATE_FAQ_SUCCESS = "{FAQ} UPDATE_FAQ_SUCCESS";
export const DELETE_FAQ = "{FAQ} DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "{FAQ} DELETE_FAQ_SUCCESS";
export const UPDATE_FAQS = "{FAQ} UPDATE_FAQS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_DMCS = "{DMC} GET_DMCS";
export const GET_DMCS_SUCCESS = "{DMC} GET_DMCS_SUCCESS";
export const GET_DMC = "{DMC} GET_DMC";
export const GET_DMC_SUCCESS = "{DMC} GET_DMC_SUCCESS";
export const CREATE_DMC = "{DMC} CREATE_DMC";
export const CREATE_DMC_SUCCESS = "{DMC} CREATE_DMC_SUCCESS";
export const READ_DMC = "{DMC} READ_DMC";
export const READ_DMC_SUCCESS = "{DMC} READ_DMC_SUCCESS";
export const UPDATE_DMC = "{DMC} UPDATE_DMC";
export const UPDATE_DMC_SUCCESS = "{DMC} UPDATE_DMC_SUCCESS";
export const DELETE_DMC = "{DMC} DELETE_DMC";
export const DELETE_DMC_SUCCESS = "{DMC} DELETE_DMC_SUCCESS";
export const UPDATE_DMCS = "{DMC} UPDATE_DMCS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_VOUCHERS = "{VOUCHER} GET_VOUCHERS";
export const GET_VOUCHERS_SUCCESS = "{VOUCHER} GET_VOUCHERS_SUCCESS";
export const GET_VOUCHER = "{VOUCHER} GET_VOUCHER";
export const GET_VOUCHER_SUCCESS = "{VOUCHER} GET_VOUCHER_SUCCESS";
export const CREATE_VOUCHER = "{VOUCHER} CREATE_VOUCHER";
export const CREATE_VOUCHER_SUCCESS = "{VOUCHER} CREATE_VOUCHER_SUCCESS";
export const READ_VOUCHER = "{VOUCHER} READ_VOUCHER";
export const READ_VOUCHER_SUCCESS = "{VOUCHER} READ_VOUCHER_SUCCESS";
export const UPDATE_VOUCHER = "{VOUCHER} UPDATE_VOUCHER";
export const UPDATE_VOUCHER_SUCCESS = "{VOUCHER} UPDATE_VOUCHER_SUCCESS";
export const DELETE_VOUCHER = "{VOUCHER} DELETE_VOUCHER";
export const DELETE_VOUCHER_SUCCESS = "{VOUCHER} DELETE_VOUCHER_SUCCESS";
export const UPDATE_VOUCHERS = "{VOUCHER} UPDATE_VOUCHERS";
// ---------------------------------------------------------------------------------------------------------------------
export const GET_COMPANY_INFOS = "{COMPANY_INFO} GET_COMPANY_INFOS";
export const GET_COMPANY_INFOS_SUCCESS =
	"{COMPANY_INFO} GET_COMPANY_INFOS_SUCCESS";
export const GET_COMPANY_INFO = "{COMPANY_INFO} GET_COMPANY_INFO";
export const GET_COMPANY_INFO_SUCCESS =
	"{COMPANY_INFO} GET_COMPANY_INFO_SUCCESS";
export const CREATE_COMPANY_INFO = "{COMPANY_INFO} CREATE_COMPANY_INFO";
export const CREATE_COMPANY_INFO_SUCCESS =
	"{COMPANY_INFO} CREATE_COMPANY_INFO_SUCCESS";
export const READ_COMPANY_INFO = "{COMPANY_INFO} READ_COMPANY_INFO";
export const READ_COMPANY_INFO_SUCCESS =
	"{COMPANY_INFO} READ_COMPANY_INFO_SUCCESS";
export const UPDATE_COMPANY_INFO = "{COMPANY_INFO} UPDATE_COMPANY_INFO";
export const UPDATE_COMPANY_INFO_SUCCESS =
	"{COMPANY_INFO} UPDATE_COMPANY_INFO_SUCCESS";
export const DELETE_COMPANY_INFO = "{COMPANY_INFO} DELETE_COMPANY_INFO";
export const DELETE_COMPANY_INFO_SUCCESS =
	"{COMPANY_INFO} DELETE_COMPANY_INFO_SUCCESS";
export const UPDATE_COMPANY_INFOS = "{COMPANY_INFO} UPDATE_COMPANY_INFOS";
// ---------------------------------------------------------------------------------------------------------------------
