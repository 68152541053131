import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateCompanyInfos,
} from "../actions";
import {
	closeCreateCompanyInfoDrawer,
	closeUpdateCompanyInfoDrawer,
} from "../actions/companyInfo";
import {
	CREATE_COMPANY_INFO,
	CREATE_COMPANY_INFO_SUCCESS,
	DELETE_COMPANY_INFO,
	DELETE_COMPANY_INFO_SUCCESS,
	GET_COMPANY_INFO,
	GET_COMPANY_INFOS,
	GET_COMPANY_INFOS_SUCCESS,
	GET_COMPANY_INFO_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_COMPANY_INFO,
	UPDATE_COMPANY_INFO_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All CompanyInfos -----------------------------------------------------------------
export const getCompanyInfosFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_COMPANY_INFOS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading CompanyInfos . . . " })
			);
			dispatch(
				listRequest(
					"listCompanyInfos",
					null,
					GET_COMPANY_INFOS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_COMPANY_INFO) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading CompanyInfo . . . " })
			);
			dispatch(
				getSingleRequest(
					"getCompanyInfo",
					action.payload,
					GET_COMPANY_INFO_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_COMPANY_INFO) {
			console.log("Create Comp", action.payload);
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New CompanyInfo . . . ",
				})
			);
			dispatch(
				updateRequest(
					"createCompanyInfo",
					action.payload,
					CREATE_COMPANY_INFO_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_COMPANY_INFO) {
			dispatch(
				updateSpinner({ show: true, message: "Updating CompanyInfo . . . " })
			);
			dispatch(
				updateRequest(
					"updateCompanyInfo",
					action.payload,
					UPDATE_COMPANY_INFO_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_COMPANY_INFO) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting CompanyInfo . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_COMPANY_INFO_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processCompanyInfosFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_COMPANY_INFOS_SUCCESS) {
			dispatch(updateCompanyInfos({ companyInfos: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_COMPANY_INFO_SUCCESS) {
			dispatch(updateCompanyInfos({ selectedCompanyInfo: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_COMPANY_INFO_SUCCESS) {
			let companyInfos = getState().companyInfo.companyInfos;
			companyInfos.push(action.payload);
			dispatch(updateCompanyInfos({ companyInfos }));
			dispatch(closeCreateCompanyInfoDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "CompanyInfo Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_COMPANY_INFO_SUCCESS) {
			let companyInfos = getState().companyInfo.companyInfos;
			let updatedCompanyInfos = companyInfos.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(
				updateCompanyInfos({
					companyInfos: updatedCompanyInfos,
					selectedCompanyInfo: null,
				})
			);
			dispatch(closeUpdateCompanyInfoDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "CompanyInfo Updated",
				})
			);
		}

		if (action.type === DELETE_COMPANY_INFO_SUCCESS) {
			let CompanyInfos = getState().CompanyInfo.CompanyInfos;
			let updatedCompanyInfos = CompanyInfos.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateCompanyInfos({ CompanyInfos: updatedCompanyInfos }));
			dispatch(closeUpdateCompanyInfoDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "CompanyInfo Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const companyInfoMdl = [getCompanyInfosFlow, processCompanyInfosFlow];
