import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateDmcs,
} from "../actions";
import { closeCreateDmcDrawer, closeUpdateDmcDrawer } from "../actions/dmcAc";
import {
	CREATE_DMC,
	CREATE_DMC_SUCCESS,
	DELETE_DMC,
	DELETE_DMC_SUCCESS,
	GET_DMC,
	GET_DMCS,
	GET_DMCS_SUCCESS,
	GET_DMC_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_DMC,
	UPDATE_DMC_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Dmcs -----------------------------------------------------------------
export const getDmcsFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DMCS) {
			dispatch(updateSpinner({ show: true, message: "Loading Dmcs . . . " }));
			dispatch(
				listRequest(
					"listDMCAccounts",
					null,
					GET_DMCS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_DMC) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(updateSpinner({ show: true, message: "Loading Dmc . . . " }));
			dispatch(
				getSingleRequest(
					"getDMCAccount",
					action.payload,
					GET_DMC_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_DMC) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Dmc . . . ",
				})
			);
			dispatch(
				updateRequest(
					"createDMCAccount",
					action.payload,
					CREATE_DMC_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_DMC) {
			dispatch(updateSpinner({ show: true, message: "Updating Dmc . . . " }));
			dispatch(
				updateRequest(
					"updateDMCAccount",
					action.payload,
					UPDATE_DMC_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_DMC) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(updateSpinner({ show: true, message: "Deleting Dmc . . . " }));
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_DMC_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processDmcsFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DMCS_SUCCESS) {
			dispatch(updateDmcs({ dmcs: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_DMC_SUCCESS) {
			dispatch(updateDmcs({ selectedDmc: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_DMC_SUCCESS) {
			let dmcs = getState().dmc.dmcs;
			dmcs.push(action.payload);
			dispatch(updateDmcs({ dmcs }));
			dispatch(closeCreateDmcDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Dmc Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_DMC_SUCCESS) {
			let dmcs = getState().dmc.dmcs;
			let updatedDmcs = dmcs.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateDmcs({ dmcs: updatedDmcs, selectedDmc: null }));
			dispatch(closeUpdateDmcDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Dmc Updated",
				})
			);
		}

		if (action.type === DELETE_DMC_SUCCESS) {
			let dmcs = getState().dmc.dmcs;
			let updatedDmcs = dmcs.filter((el) => el.id !== action.deleteId);
			dispatch(updateDmcs({ dmcs: updatedDmcs }));
			dispatch(closeUpdateDmcDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Dmc Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const dmcMdl = [getDmcsFlow, processDmcsFlow];
