import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateDemoPackages,
} from "../actions";
import {
	closeCreateDemoPackageDrawer,
	closeUpdateDemoPackageDrawer,
} from "../actions/demoPackage";
import {
	CREATE_DEMO_PACKAGE,
	CREATE_DEMO_PACKAGE_SUCCESS,
	DELETE_DEMO_PACKAGE,
	DELETE_DEMO_PACKAGE_SUCCESS,
	GET_DEMO_PACKAGE,
	GET_DEMO_PACKAGES,
	GET_DEMO_PACKAGES_SUCCESS,
	GET_DEMO_PACKAGE_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_DEMO_PACKAGE,
	UPDATE_DEMO_PACKAGE_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All demoPackages -----------------------------------------------------------------
export const getDemoPackagesFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DEMO_PACKAGES) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Loading Display Packages . . . ",
				})
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		URL,
			// 		null,
			// 		GET_DEMO_PACKAGES_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );

			dispatch(
				listRequest(
					"listStaticPackages",
					null,
					GET_DEMO_PACKAGES_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_DEMO_PACKAGE) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading Display Package . . . " })
			);
			// dispatch(
			// 	apiRequest(
			// 		"GET",
			// 		newURL,
			// 		null,
			// 		GET_DEMO_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			// dispatch(
			// 	listRequest(
			// 		"listEmployees",
			// 		null,
			// 		GET_DEMO_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				getSingleRequest(
					"getStaticPackage",
					action.payload,
					GET_DEMO_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_DEMO_PACKAGE) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Display Package . . . ",
				})
			);
			// dispatch(
			// 	apiRequest(
			// 		"POST",
			// 		URL,
			// 		action.payload,
			// 		CREATE_DEMO_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				updateRequest(
					"createStaticPackage",
					action.payload,
					CREATE_DEMO_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_DEMO_PACKAGE) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({
					show: true,
					message: "Updating Display Package . . . ",
				})
			);
			// dispatch(
			// 	apiRequest(
			// 		"PATCH",
			// 		newURL,
			// 		action.payload,
			// 		UPDATE_DEMO_PACKAGE_SUCCESS,
			// 		UPDATE_NOTIFICATION_API
			// 	)
			// );
			dispatch(
				updateRequest(
					"updateStaticPackage",
					action.payload,
					UPDATE_DEMO_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_DEMO_PACKAGE) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({
					show: true,
					message: "Deleting Display Package . . . ",
				})
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_DEMO_PACKAGE_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processDemoPackagesFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DEMO_PACKAGES_SUCCESS) {
			dispatch(updateDemoPackages({ demoPackages: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_DEMO_PACKAGE_SUCCESS) {
			dispatch(updateDemoPackages({ selectedDemoPackage: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_DEMO_PACKAGE_SUCCESS) {
			let demoPackages = getState().demoPackage.demoPackages;
			demoPackages.push(action.payload);
			dispatch(updateDemoPackages({ demoPackages }));
			dispatch(closeCreateDemoPackageDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Display Package Created Successfully",
				})
			);
		}

		if (action.type === UPDATE_DEMO_PACKAGE_SUCCESS) {
			let demoPackages = getState().demoPackage.demoPackages;
			let updateddemoPackages = demoPackages.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateDemoPackages({ demoPackages: updateddemoPackages }));
			dispatch(closeUpdateDemoPackageDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Display Package Updated",
				})
			);
		}

		if (action.type === DELETE_DEMO_PACKAGE_SUCCESS) {
			let demoPackages = getState().demoPackage.demoPackages;
			let updateddemoPackages = demoPackages.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateDemoPackages({ demoPackages: updateddemoPackages }));
			dispatch(closeUpdateDemoPackageDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Display Package Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const demoPackageMdl = [getDemoPackagesFlow, processDemoPackagesFlow];
