import {
	CREATE_LEAD,
	DELETE_LEAD,
	GET_LEAD,
	GET_LEADS,
	UPDATE_LEAD,
	UPDATE_LEADS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateLeadDrawer() {
	return {
		type: UPDATE_LEADS,
		payload: { createLeadDrawer: true },
	};
}

export function closeCreateLeadDrawer() {
	return {
		type: UPDATE_LEADS,
		payload: { createLeadDrawer: false },
	};
}

export function openUpdateLeadDrawer() {
	return {
		type: UPDATE_LEADS,
		payload: { updateLeadDrawer: true },
	};
}

export function closeUpdateLeadDrawer() {
	return {
		type: UPDATE_LEADS,
		payload: { updateLeadDrawer: false },
	};
}

export function openReadLeadDrawer() {
	return {
		type: UPDATE_LEADS,
		payload: { readLeadDrawer: true },
	};
}

export function closeReadLeadDrawer() {
	return {
		type: UPDATE_LEADS,
		payload: { readLeadDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllLeads = () => {
	return {
		type: GET_LEADS,
	};
};

export const getSelectedLead = (payload) => {
	return {
		type: GET_LEAD,
		payload,
	};
};

export const createNewLead = (payload) => {
	return {
		type: CREATE_LEAD,
		payload,
	};
};

export const updateLead = (payload) => {
	return {
		type: UPDATE_LEAD,
		payload,
	};
};

export const deleteLead = (payload) => {
	return {
		type: DELETE_LEAD,
		payload,
	};
};

export const updateLeads = (data) => {
	return { type: UPDATE_LEADS, payload: data };
};
