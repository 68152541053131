import {
	CREATE_DMC,
	DELETE_DMC,
	GET_DMC,
	GET_DMCS,
	UPDATE_DMC,
	UPDATE_DMCS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateDmcDrawer() {
	return {
		type: UPDATE_DMCS,
		payload: { createDmcDrawer: true },
	};
}

export function closeCreateDmcDrawer() {
	return {
		type: UPDATE_DMCS,
		payload: { createDmcDrawer: false },
	};
}

export function openUpdateDmcDrawer() {
	return {
		type: UPDATE_DMCS,
		payload: { updateDmcDrawer: true },
	};
}

export function closeUpdateDmcDrawer() {
	return {
		type: UPDATE_DMCS,
		payload: { updateDmcDrawer: false },
	};
}

export function openReadDmcDrawer() {
	return {
		type: UPDATE_DMCS,
		payload: { readDmcDrawer: true },
	};
}

export function closeReadDmcDrawer() {
	return {
		type: UPDATE_DMCS,
		payload: { readDmcDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllDmcs = () => {
	return {
		type: GET_DMCS,
	};
};

export const getSelectedDmc = (payload) => {
	return {
		type: GET_DMC,
		payload,
	};
};

export const createNewDmc = (payload) => {
	return {
		type: CREATE_DMC,
		payload,
	};
};

export const updateDmc = (payload) => {
	return {
		type: UPDATE_DMC,
		payload,
	};
};

export const deleteDmc = (payload) => {
	return {
		type: DELETE_DMC,
		payload,
	};
};

export const updateDmcs = (data) => {
	return { type: UPDATE_DMCS, payload: data };
};
