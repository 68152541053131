import { UPDATE_EMPLOYEES } from "../constants";
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATE_EMPLOYEES]: (state, action) => {
		return { ...state, ...action.payload };
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	createEmployeeDrawer: false,
	updateEmployeeDrawer: false,
	readEmployeeDrawer: false,
	employees: [],
	selectedEmployee: null,
	loggedInEmployee: null,
	employeeRole: null,
	authKey: null,
};
export default function employeeReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
