import { GoogleOutlined } from "@ant-design/icons";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Button } from "antd";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import "./App.css";

const LoggedInUserCheckLayer = React.lazy(() =>
	import("./redirect-pages/LoggedInUserCheckLayer")
);

const GoogleLogin = () =>
	Auth.federatedSignIn({
		provider: CognitoHostedUIIdentityProvider.Google,
	});

const LoginButton = {
	width: 300,
	height: 50,
	marginLeft: 50,
	fontSize: 20,
	boxShadow: "5px 5px 15px #fff",
};
export default function App() {
	const [user, setUser] = useState(null);
	function getUser() {
		return Auth.currentAuthenticatedUser()
			.then((userData) => userData)
			.catch(() => console.log("Not signed in"));
	}
	useEffect(() => {
		Hub.listen("auth", ({ payload: { event, data } }) => {
			switch (event) {
				case "signIn":
				case "cognitoHostedUI":
					getUser().then((userData) => {
						console.log(userData.attributes);

						setUser(userData);
					});
					break;
				case "signOut":
					setUser(null);
					break;
				case "signIn_failure":
				case "cognitoHostedUI_failure":
					console.log("Sign in failure", data);
					break;
			}
		});
		getUser().then((userData) => {
			console.log(userData);
			setUser(userData);
		});
		// Auth.signOut();
	}, []);

	// return <LoggedInUserCheckLayer user={user} />;
	return (
		<>
			{user ? (
				<>
					<LoggedInUserCheckLayer user={user} />
				</>
			) : (
				<div className='site-card-border-less-wrapper'>
					<Button
						type='primary'
						icon={<GoogleOutlined />}
						size='Large'
						onClick={GoogleLogin}
						style={LoginButton}
					>
						Sign In with Google
					</Button>
				</div>
			)}
		</>
	);
}
