import {
	CREATE_ROLE,
	DELETE_ROLE,
	GET_ROLE,
	GET_ROLES,
	UPDATE_ROLE,
	UPDATE_ROLES,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreateRoleDrawer() {
	return {
		type: UPDATE_ROLES,
		payload: { createRoleDrawer: true },
	};
}

export function closeCreateRoleDrawer() {
	return {
		type: UPDATE_ROLES,
		payload: { createRoleDrawer: false },
	};
}

export function openUpdateRoleDrawer() {
	return {
		type: UPDATE_ROLES,
		payload: { updateRoleDrawer: true },
	};
}

export function closeUpdateRoleDrawer() {
	return {
		type: UPDATE_ROLES,
		payload: { updateRoleDrawer: false },
	};
}

export function openReadRoleDrawer() {
	return {
		type: UPDATE_ROLES,
		payload: { readRoleDrawer: true },
	};
}

export function closeReadRoleDrawer() {
	return {
		type: UPDATE_ROLES,
		payload: { readRoleDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllRoles = () => {
	return {
		type: GET_ROLES,
	};
};

export const getSelectedRole = (payload) => {
	return {
		type: GET_ROLE,
		payload,
	};
};

export const createNewRole = (payload) => {
	return {
		type: CREATE_ROLE,
		payload,
	};
};

export const updateRole = (payload) => {
	return {
		type: UPDATE_ROLE,
		payload,
	};
};

export const deleteRole = (payload) => {
	return {
		type: DELETE_ROLE,
		payload,
	};
};

export const updateRoles = (data) => {
	return { type: UPDATE_ROLES, payload: data };
};
