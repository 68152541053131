import {
	CREATE_POLICY,
	DELETE_POLICY,
	GET_POLICY,
	GET_POLICYS,
	UPDATE_POLICY,
	UPDATE_POLICYS,
} from "../constants";
// ------------------------------------
// Actions
// ------------------------------------
export function openCreatePolicyDrawer() {
	return {
		type: UPDATE_POLICYS,
		payload: { createPolicyDrawer: true },
	};
}

export function closeCreatePolicyDrawer() {
	return {
		type: UPDATE_POLICYS,
		payload: { createPolicyDrawer: false },
	};
}

export function openUpdatePolicyDrawer() {
	return {
		type: UPDATE_POLICYS,
		payload: { updatePolicyDrawer: true },
	};
}

export function closeUpdatePolicyDrawer() {
	return {
		type: UPDATE_POLICYS,
		payload: { updatePolicyDrawer: false },
	};
}

export function openReadPolicyDrawer() {
	return {
		type: UPDATE_POLICYS,
		payload: { readPolicyDrawer: true },
	};
}

export function closeReadPolicyDrawer() {
	return {
		type: UPDATE_POLICYS,
		payload: { readPolicyDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllPolicys = () => {
	return {
		type: GET_POLICYS,
	};
};

export const getSelectedPolicy = (payload) => {
	return {
		type: GET_POLICY,
		payload,
	};
};

export const createNewPolicy = (payload) => {
	return {
		type: CREATE_POLICY,
		payload,
	};
};

export const updatePolicy = (payload) => {
	return {
		type: UPDATE_POLICY,
		payload,
	};
};

export const deletePolicy = (payload) => {
	return {
		type: DELETE_POLICY,
		payload,
	};
};

export const updatePolicys = (data) => {
	return { type: UPDATE_POLICYS, payload: data };
};
