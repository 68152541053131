import {
	CREATE_ACCOMMODATION,
	DELETE_ACCOMMODATION,
	GET_ACCOMMODATION,
	GET_ACCOMMODATIONS,
	UPDATE_ACCOMMODATION,
	UPDATE_ACCOMMODATIONS,
} from "../constants";

// ------------------------------------
// Actions
// ------------------------------------
export function openCreateAccommodationDrawer() {
	return {
		type: UPDATE_ACCOMMODATIONS,
		payload: { createAccommodationDrawer: true },
	};
}

export function closeCreateAccommodationDrawer() {
	return {
		type: UPDATE_ACCOMMODATIONS,
		payload: { createAccommodationDrawer: false },
	};
}

export function openUpdateAccommodationDrawer() {
	return {
		type: UPDATE_ACCOMMODATIONS,
		payload: { updateAccommodationDrawer: true },
	};
}

export function closeUpdateAccommodationDrawer() {
	return {
		type: UPDATE_ACCOMMODATIONS,
		payload: { updateAccommodationDrawer: false },
	};
}

export function openReadAccommodationDrawer() {
	return {
		type: UPDATE_ACCOMMODATIONS,
		payload: { readAccommodationDrawer: true },
	};
}

export function closeReadAccommodationDrawer() {
	return {
		type: UPDATE_ACCOMMODATIONS,
		payload: { readAccommodationDrawer: false },
	};
}
// ------------------------------------
// Async Actions
// ------------------------------------

export const getAllAccommodations = () => {
	return {
		type: GET_ACCOMMODATIONS,
	};
};

export const getSelectedAccommodation = (payload) => {
	return {
		type: GET_ACCOMMODATION,
		payload,
	};
};

export const createNewAccommodation = (payload) => {
	return {
		type: CREATE_ACCOMMODATION,
		payload,
	};
};

export const updateAccommodation = (payload) => {
	return {
		type: UPDATE_ACCOMMODATION,
		payload,
	};
};

export const deleteAccommodation = (payload) => {
	return {
		type: DELETE_ACCOMMODATION,
		payload,
	};
};

export const updateAccommodations = (data) => {
	return { type: UPDATE_ACCOMMODATIONS, payload: data };
};
