/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPolicy = /* GraphQL */ `
  mutation CreatePolicy(
    $input: CreatePolicyInput!
    $condition: ModelPolicyConditionInput
  ) {
    createPolicy(input: $input, condition: $condition) {
      id
      title
      descriptionLines
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePolicy = /* GraphQL */ `
  mutation UpdatePolicy(
    $input: UpdatePolicyInput!
    $condition: ModelPolicyConditionInput
  ) {
    updatePolicy(input: $input, condition: $condition) {
      id
      title
      descriptionLines
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePolicy = /* GraphQL */ `
  mutation DeletePolicy(
    $input: DeletePolicyInput!
    $condition: ModelPolicyConditionInput
  ) {
    deletePolicy(input: $input, condition: $condition) {
      id
      title
      descriptionLines
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStaticPackage = /* GraphQL */ `
  mutation CreateStaticPackage(
    $input: CreateStaticPackageInput!
    $condition: ModelStaticPackageConditionInput
  ) {
    createStaticPackage(input: $input, condition: $condition) {
      id
      title
      duration
      description
      itinerary
      pricing
      locations
      addOns
      inclusions
      exclusions
      terms
      tags
      isActive
      isApproved
      approvedBy
      destinationId
      createdBy
      otherExtra
      multilineDesc
      isMultilineDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStaticPackage = /* GraphQL */ `
  mutation UpdateStaticPackage(
    $input: UpdateStaticPackageInput!
    $condition: ModelStaticPackageConditionInput
  ) {
    updateStaticPackage(input: $input, condition: $condition) {
      id
      title
      duration
      description
      itinerary
      pricing
      locations
      addOns
      inclusions
      exclusions
      terms
      tags
      isActive
      isApproved
      approvedBy
      destinationId
      createdBy
      otherExtra
      multilineDesc
      isMultilineDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStaticPackage = /* GraphQL */ `
  mutation DeleteStaticPackage(
    $input: DeleteStaticPackageInput!
    $condition: ModelStaticPackageConditionInput
  ) {
    deleteStaticPackage(input: $input, condition: $condition) {
      id
      title
      duration
      description
      itinerary
      pricing
      locations
      addOns
      inclusions
      exclusions
      terms
      tags
      isActive
      isApproved
      approvedBy
      destinationId
      createdBy
      otherExtra
      multilineDesc
      isMultilineDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLeadPackage = /* GraphQL */ `
  mutation CreateLeadPackage(
    $input: CreateLeadPackageInput!
    $condition: ModelLeadPackageConditionInput
  ) {
    createLeadPackage(input: $input, condition: $condition) {
      id
      title
      duration
      tripSummary
      tripCordinator
      markupDetails
      travellerDetails
      itinerary
      hotels
      transports
      flights
      totalPrice
      travelDate
      locations
      paymentOptions
      packageUrl
      inclusions
      exclusions
      extraInfo
      terms
      isActive
      isApproved
      approvedBy
      destinationId
      leadId
      createdBy
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLeadPackage = /* GraphQL */ `
  mutation UpdateLeadPackage(
    $input: UpdateLeadPackageInput!
    $condition: ModelLeadPackageConditionInput
  ) {
    updateLeadPackage(input: $input, condition: $condition) {
      id
      title
      duration
      tripSummary
      tripCordinator
      markupDetails
      travellerDetails
      itinerary
      hotels
      transports
      flights
      totalPrice
      travelDate
      locations
      paymentOptions
      packageUrl
      inclusions
      exclusions
      extraInfo
      terms
      isActive
      isApproved
      approvedBy
      destinationId
      leadId
      createdBy
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLeadPackage = /* GraphQL */ `
  mutation DeleteLeadPackage(
    $input: DeleteLeadPackageInput!
    $condition: ModelLeadPackageConditionInput
  ) {
    deleteLeadPackage(input: $input, condition: $condition) {
      id
      title
      duration
      tripSummary
      tripCordinator
      markupDetails
      travellerDetails
      itinerary
      hotels
      transports
      flights
      totalPrice
      travelDate
      locations
      paymentOptions
      packageUrl
      inclusions
      exclusions
      extraInfo
      terms
      isActive
      isApproved
      approvedBy
      destinationId
      leadId
      createdBy
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDestination = /* GraphQL */ `
  mutation CreateDestination(
    $input: CreateDestinationInput!
    $condition: ModelDestinationConditionInput
  ) {
    createDestination(input: $input, condition: $condition) {
      id
      name
      country
      cities
      places
      description
      isActive
      photos
      banners
      terms
      notices
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDestination = /* GraphQL */ `
  mutation UpdateDestination(
    $input: UpdateDestinationInput!
    $condition: ModelDestinationConditionInput
  ) {
    updateDestination(input: $input, condition: $condition) {
      id
      name
      country
      cities
      places
      description
      isActive
      photos
      banners
      terms
      notices
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDestination = /* GraphQL */ `
  mutation DeleteDestination(
    $input: DeleteDestinationInput!
    $condition: ModelDestinationConditionInput
  ) {
    deleteDestination(input: $input, condition: $condition) {
      id
      name
      country
      cities
      places
      description
      isActive
      photos
      banners
      terms
      notices
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      name
      department
      mobile
      altMobile
      address
      birthdate
      joiningDate
      gender
      isActive
      email
      profilePic
      roleId
      emergencyContactName
      emergencyContactNumber
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      name
      department
      mobile
      altMobile
      address
      birthdate
      joiningDate
      gender
      isActive
      email
      profilePic
      roleId
      emergencyContactName
      emergencyContactNumber
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      name
      department
      mobile
      altMobile
      address
      birthdate
      joiningDate
      gender
      isActive
      email
      profilePic
      roleId
      emergencyContactName
      emergencyContactNumber
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      permissions
      isAdmin
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      permissions
      isAdmin
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      permissions
      isAdmin
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDMCAccount = /* GraphQL */ `
  mutation CreateDMCAccount(
    $input: CreateDMCAccountInput!
    $condition: ModelDMCAccountConditionInput
  ) {
    createDMCAccount(input: $input, condition: $condition) {
      id
      name
      contacts
      address
      isActive
      bankDetails
      destinationId
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDMCAccount = /* GraphQL */ `
  mutation UpdateDMCAccount(
    $input: UpdateDMCAccountInput!
    $condition: ModelDMCAccountConditionInput
  ) {
    updateDMCAccount(input: $input, condition: $condition) {
      id
      name
      contacts
      address
      isActive
      bankDetails
      destinationId
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDMCAccount = /* GraphQL */ `
  mutation DeleteDMCAccount(
    $input: DeleteDMCAccountInput!
    $condition: ModelDMCAccountConditionInput
  ) {
    deleteDMCAccount(input: $input, condition: $condition) {
      id
      name
      contacts
      address
      isActive
      bankDetails
      destinationId
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      name
      email
      mobile
      altMobile
      city
      travelDate
      destination
      adults
      children
      infants
      description
      schedule
      followUps
      status
      isActive
      otherExtra
      assignedTo
      assignedBy
      leadPackages {
        items {
          id
          title
          duration
          tripSummary
          tripCordinator
          markupDetails
          travellerDetails
          itinerary
          hotels
          transports
          flights
          totalPrice
          travelDate
          locations
          paymentOptions
          packageUrl
          inclusions
          exclusions
          extraInfo
          terms
          isActive
          isApproved
          approvedBy
          destinationId
          leadId
          createdBy
          otherExtra
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      name
      email
      mobile
      altMobile
      city
      travelDate
      destination
      adults
      children
      infants
      description
      schedule
      followUps
      status
      isActive
      otherExtra
      assignedTo
      assignedBy
      leadPackages {
        items {
          id
          title
          duration
          tripSummary
          tripCordinator
          markupDetails
          travellerDetails
          itinerary
          hotels
          transports
          flights
          totalPrice
          travelDate
          locations
          paymentOptions
          packageUrl
          inclusions
          exclusions
          extraInfo
          terms
          isActive
          isApproved
          approvedBy
          destinationId
          leadId
          createdBy
          otherExtra
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      name
      email
      mobile
      altMobile
      city
      travelDate
      destination
      adults
      children
      infants
      description
      schedule
      followUps
      status
      isActive
      otherExtra
      assignedTo
      assignedBy
      leadPackages {
        items {
          id
          title
          duration
          tripSummary
          tripCordinator
          markupDetails
          travellerDetails
          itinerary
          hotels
          transports
          flights
          totalPrice
          travelDate
          locations
          paymentOptions
          packageUrl
          inclusions
          exclusions
          extraInfo
          terms
          isActive
          isApproved
          approvedBy
          destinationId
          leadId
          createdBy
          otherExtra
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createViaOrder = /* GraphQL */ `
  mutation CreateViaOrder(
    $input: CreateViaOrderInput!
    $condition: ModelViaOrderConditionInput
  ) {
    createViaOrder(input: $input, condition: $condition) {
      id
      reference
      viaOrderRef
      ticketUrl
      orderAmount
      amountToCharge
      convenienceFee
      discount
      isFlightBooked
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      travellersData
      deliveryData
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateViaOrder = /* GraphQL */ `
  mutation UpdateViaOrder(
    $input: UpdateViaOrderInput!
    $condition: ModelViaOrderConditionInput
  ) {
    updateViaOrder(input: $input, condition: $condition) {
      id
      reference
      viaOrderRef
      ticketUrl
      orderAmount
      amountToCharge
      convenienceFee
      discount
      isFlightBooked
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      travellersData
      deliveryData
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteViaOrder = /* GraphQL */ `
  mutation DeleteViaOrder(
    $input: DeleteViaOrderInput!
    $condition: ModelViaOrderConditionInput
  ) {
    deleteViaOrder(input: $input, condition: $condition) {
      id
      reference
      viaOrderRef
      ticketUrl
      orderAmount
      amountToCharge
      convenienceFee
      discount
      isFlightBooked
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      travellersData
      deliveryData
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTripOrder = /* GraphQL */ `
  mutation CreateTripOrder(
    $input: CreateTripOrderInput!
    $condition: ModelTripOrderConditionInput
  ) {
    createTripOrder(input: $input, condition: $condition) {
      id
      totalAmount
      isPaymentComplete
      leadId
      packageId
      tripRef
      isPaymentOption
      paymentOptions
      prevPayOptions
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTripOrder = /* GraphQL */ `
  mutation UpdateTripOrder(
    $input: UpdateTripOrderInput!
    $condition: ModelTripOrderConditionInput
  ) {
    updateTripOrder(input: $input, condition: $condition) {
      id
      totalAmount
      isPaymentComplete
      leadId
      packageId
      tripRef
      isPaymentOption
      paymentOptions
      prevPayOptions
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTripOrder = /* GraphQL */ `
  mutation DeleteTripOrder(
    $input: DeleteTripOrderInput!
    $condition: ModelTripOrderConditionInput
  ) {
    deleteTripOrder(input: $input, condition: $condition) {
      id
      totalAmount
      isPaymentComplete
      leadId
      packageId
      tripRef
      isPaymentOption
      paymentOptions
      prevPayOptions
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      transactionId
      amount
      status
      channel
      origin
      type
      orderId
      optionId
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      transactionId
      amount
      status
      channel
      origin
      type
      orderId
      optionId
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      transactionId
      amount
      status
      channel
      origin
      type
      orderId
      optionId
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVoucher = /* GraphQL */ `
  mutation CreateVoucher(
    $input: CreateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    createVoucher(input: $input, condition: $condition) {
      id
      orderId
      summary
      hotels
      flights
      otherDocs
      guests
      cordinators
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVoucher = /* GraphQL */ `
  mutation UpdateVoucher(
    $input: UpdateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    updateVoucher(input: $input, condition: $condition) {
      id
      orderId
      summary
      hotels
      flights
      otherDocs
      guests
      cordinators
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVoucher = /* GraphQL */ `
  mutation DeleteVoucher(
    $input: DeleteVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    deleteVoucher(input: $input, condition: $condition) {
      id
      orderId
      summary
      hotels
      flights
      otherDocs
      guests
      cordinators
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyInfo = /* GraphQL */ `
  mutation CreateCompanyInfo(
    $input: CreateCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    createCompanyInfo(input: $input, condition: $condition) {
      id
      legalInfo
      bankingInfo
      contacts
      websiteManagement
      salesMarkUp
      currentPackageInfo
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyInfo = /* GraphQL */ `
  mutation UpdateCompanyInfo(
    $input: UpdateCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    updateCompanyInfo(input: $input, condition: $condition) {
      id
      legalInfo
      bankingInfo
      contacts
      websiteManagement
      salesMarkUp
      currentPackageInfo
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyInfo = /* GraphQL */ `
  mutation DeleteCompanyInfo(
    $input: DeleteCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    deleteCompanyInfo(input: $input, condition: $condition) {
      id
      legalInfo
      bankingInfo
      contacts
      websiteManagement
      salesMarkUp
      currentPackageInfo
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHotel = /* GraphQL */ `
  mutation CreateHotel(
    $input: CreateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    createHotel(input: $input, condition: $condition) {
      id
      name
      contacts
      address
      email
      phone
      website
      destinationId
      city
      description
      isActive
      terms
      notices
      services
      photos
      roomTypes
      checkInTime
      checkOutTime
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHotel = /* GraphQL */ `
  mutation UpdateHotel(
    $input: UpdateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    updateHotel(input: $input, condition: $condition) {
      id
      name
      contacts
      address
      email
      phone
      website
      destinationId
      city
      description
      isActive
      terms
      notices
      services
      photos
      roomTypes
      checkInTime
      checkOutTime
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHotel = /* GraphQL */ `
  mutation DeleteHotel(
    $input: DeleteHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    deleteHotel(input: $input, condition: $condition) {
      id
      name
      contacts
      address
      email
      phone
      website
      destinationId
      city
      description
      isActive
      terms
      notices
      services
      photos
      roomTypes
      checkInTime
      checkOutTime
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDay = /* GraphQL */ `
  mutation CreateDay(
    $input: CreateDayInput!
    $condition: ModelDayConditionInput
  ) {
    createDay(input: $input, condition: $condition) {
      id
      title
      address
      destinationId
      city
      description
      isActive
      totalPrice
      transport
      time
      duration
      meal
      terms
      notices
      services
      activities
      photos
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDay = /* GraphQL */ `
  mutation UpdateDay(
    $input: UpdateDayInput!
    $condition: ModelDayConditionInput
  ) {
    updateDay(input: $input, condition: $condition) {
      id
      title
      address
      destinationId
      city
      description
      isActive
      totalPrice
      transport
      time
      duration
      meal
      terms
      notices
      services
      activities
      photos
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDay = /* GraphQL */ `
  mutation DeleteDay(
    $input: DeleteDayInput!
    $condition: ModelDayConditionInput
  ) {
    deleteDay(input: $input, condition: $condition) {
      id
      title
      address
      destinationId
      city
      description
      isActive
      totalPrice
      transport
      time
      duration
      meal
      terms
      notices
      services
      activities
      photos
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransport = /* GraphQL */ `
  mutation CreateTransport(
    $input: CreateTransportInput!
    $condition: ModelTransportConditionInput
  ) {
    createTransport(input: $input, condition: $condition) {
      id
      title
      fromAddress
      destinationId
      from
      to
      toAddress
      description
      isActive
      totalPrice
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransport = /* GraphQL */ `
  mutation UpdateTransport(
    $input: UpdateTransportInput!
    $condition: ModelTransportConditionInput
  ) {
    updateTransport(input: $input, condition: $condition) {
      id
      title
      fromAddress
      destinationId
      from
      to
      toAddress
      description
      isActive
      totalPrice
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransport = /* GraphQL */ `
  mutation DeleteTransport(
    $input: DeleteTransportInput!
    $condition: ModelTransportConditionInput
  ) {
    deleteTransport(input: $input, condition: $condition) {
      id
      title
      fromAddress
      destinationId
      from
      to
      toAddress
      description
      isActive
      totalPrice
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
